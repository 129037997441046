import axios from "axios";
import {
  ERROR_Profile,
  LOADING_Profile,
  SUCCESS_Profile,
  GET_VENDOR_DATA,
  GET_VENDOR_Loading,
  GET_VENDOR_ERROR,
  GET_USER_DATA,
  GET_USER_Loading,
  GET_USER_ERROR
} from "./Profile.Type";
import { LIVE_URL2, baseUrl } from "../config/Commen";
import { toast } from "react-toastify";

// export const ProfileAction = (token) => (dispatch) => {
//     const headers = {
//         'token': `${token}`
//     };
//     dispatch({ type: LOADING_Profile })

//     try {

//         axios.get(`https://tax-service.onrender.com/profile_get`, { headers }).then(res => {
//             dispatch({ type: SUCCESS_Profile, payload: res.data.profile_data});
//             // console.log("res", res.data.profile_data);
//         })

//     }
//     catch (err) {
//         dispatch({ type: ERROR_Profile, payload: err });

//         console.log(err);
//     }
// }

export const ProfileActionUpdate = (token, creds) => (dispatch) => {
  console.log("acid", creds);
  const headers = {
    token: `${token}`,
  };
  dispatch({ type: LOADING_Profile });

  try {
    axios.put(`${LIVE_URL2}/profile_update`, creds, { headers }).then((res) => {
      dispatch({ type: SUCCESS_Profile, payload: res.data.profile_data });
      console.log("resup", res);
    });
  } catch (err) {
    dispatch({ type: ERROR_Profile, payload: err });

    console.log(err);
  }
};

export const handleGetVendor = (dispatch) => {
  dispatch({ type: GET_VENDOR_Loading });

  axios
    .get(`${baseUrl}/CA/Vendor/vendorProfile`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({ type: GET_VENDOR_DATA, payload: res.data.result });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_VENDOR_ERROR });
    });
};

export const handleUpdateVendorProfile = (formData) => (dispatch) => {
  axios
    .put(`${baseUrl}/CA/Vendor/UpdateVendorProfile`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      // console.log("updateVendor", res.data)
      if (res.data.message == "Profile updated successfully.") {
        toast.success("Vendor Profile Updated!");
        handleGetVendor(dispatch);
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("Error, Something Went Wrong!");
    });
};

export const handleChangeVendorPassword = (data) => (dispatch) => {
  axios
    .put(`${baseUrl}/CA/Vendor/changePassword`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      console.log("change password", res.data);
      if (res.data.message == "Password successfully updated") {
        toast.success("Vendor Password Changed!");
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("Error, Something Went Wrong!");
    });
};


export const handleGetUser = (dispatch) => {
  dispatch({ type: GET_USER_Loading });

  axios
    .get(`${baseUrl}/CA/User/userProfile`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      console.log("user", res.data.result)
      dispatch({ type: GET_USER_DATA, payload: res.data.result });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: GET_USER_ERROR });
    });
};

export const handleUpdateUserProfile = (formData) => (dispatch) => {
  axios
    .put(`${baseUrl}/CA/User/UpdatUserProfile`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      // console.log("updateVendor", res.data)
      if (res.data.message == "Profile updated successfully.") {
        toast.success("User Profile Updated!");
        handleGetUser(dispatch);
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("Error, Something Went Wrong!");
    });
};

export const handleCreateUser=(data, onClose)=>(dispatch)=>{

  axios
    .post(`${baseUrl}/CA/User/registerByAdminOrVendor`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res)=>{
      // console.log("createUser", res.data)
      if(res.data.message=="All the details has been sent to User mailId..."){
        toast.success("User Registered Successfully!");
        onClose()
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("Error, Something Went Wrong!");
    });
}


export const handleUplodUserDocument = (formData) => (dispatch) => {
  axios
    .put(`${baseUrl}/CA/User/UpdatUserProfile`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      console.log("updateUser", res.data)
      if (res.data.message == "Profile updated successfully.") {
        toast.success("Document uploaded successfully!");
        handleGetUser(dispatch);
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("Error, Something Went Wrong!");
    });
};

export const handleUploadVendorDocument = (formData) => (dispatch) => {
  axios
    .put(`${baseUrl}/CA/Vendor/UpdateVendorProfile`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      // console.log("updateVendor", res.data)
      if (res.data.message == "Profile updated successfully.") {
        toast.success("Document uploaded successfully!");
        handleGetVendor(dispatch);
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("Error, Something Went Wrong!");
    });
};

export const handleChangeUserPassword = (data) => (dispatch) => {
  axios
    .put(`${baseUrl}/CA/User/changePassword`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      console.log("change password", res.data);
      if (res.data.message == "Password successfully updated") {
        toast.success("User Password Changed!");
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("Error, Something Went Wrong!");
    });
};

export const handleUserComplaint=(data)=>(dispatch)=>{
  axios
  .post(`${baseUrl}/CA/Complaint/createComplaintByUser`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
  .then((res)=>{
    console.log(res.data)
    if(res.data.message=="complaint creation successfull."){
      toast.success("Complaint Created Successfully!");

    }
  })
  .catch((err)=>{
    console.log(err)
    toast.error("Error, Something Went Wrong!");
  })
}

export const handleVendorComplaint=(data)=>(dispatch)=>{
  axios
  .post(`${baseUrl}/CA/Complaint/createComplaintByVendor`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
  .then((res)=>{
    console.log(res.data)
    if(res.data.message=="complaint creation successfull."){
      toast.success("Complaint Created Successfully!");

    }
  })
  .catch((err)=>{
    console.log(err)
    toast.error("Error, Something Went Wrong!");
  })
}