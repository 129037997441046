import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  Text,
  Switch,
} from "@chakra-ui/react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Slidebar from "../Slidebar/Slidebar";
import Company_name from "../Company_name/Company_name";
import remove from "../../../assets/remove.png";
import print4 from "../../../assets/print4.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getStockAction,
  postStockAction,
  deleteStockAction,
} from "../../../../Redux/Stocks/stock.action";
import {
  postCategoryAction,
  getCategoriesAction,
} from "../../../../Redux/Category/category.actions";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FaEdit, FaTrash } from "react-icons/fa";
import moment from "moment/moment";
import { Inputvalidate } from "../../../helpers/inputValidate";
import { hasValidationError, validationError } from "../../../helpers/Frontend";

const UnitDialog = ({
  showUnitDialog,
  handleEditUnitClick,
  handleBasicUnitChange,
  handleSecondaryUnitChange,
  handleConversionRateChange,
  handleSaveUnit,
  handleCancelUnit,
  selectedBasicUnit,
  selectedSecondaryUnit,
  conversionRate,
}) => {
  const [data,setData] = useState({
    selectedBasicUnit:"",
selectedSecondaryUnit:"",
conversionRate:""
  })
  return (
    <>
      {showUnitDialog && (
        <Modal isOpen={showUnitDialog} onClose={handleCancelUnit}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Unit</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl margin={"10px"}>
                <FormLabel>Select Basic Unit:</FormLabel>
                <select
                  value={data.selectedBasicUnit}
                  onChange={(e)=>setData(pre=>({...pre,selectedBasicUnit:e.target.value}))}
                >
                  <option value="">None</option>
                  <option value="bags">BAGS(Bag)</option>
                  <option value="bottle">BOTTLE(Btl)</option>
                  <option value="box">BOX(Box)</option>
                  <option value="bundles">BUNDLES(Bdl)</option>
                  <option value="cans">CANS(can)</option>
                  <option value="cartons">CARTONS(Ctn)</option>
                  <option value="dogens">DOGENS(Dzn)</option>
                  <option value="grammes">GRAMMES(GM)</option>
                  <option value="kilograms">KILOGRAMS(KG)</option>
                  <option value="litters">LITTERS(Ltr)</option>
                  <option value="meaters">MEATERS(Mtr)</option>
                  <option value="mililiter">MILILITER(Ml)</option>
                  <option value="numbers">NUMBERS(Nos)</option>
                  <option value="packs">PACKS(Pac)</option>
                  <option value="pairs">PAIRS(Prs)</option>
                  <option value="pieces">PIECES(Pcs)</option>
                  <option value="quintals">QUINTALS(Qtl)</option>
                  <option value="rolls">ROLLS(Rol)</option>
                  <option value="squarefeet">SQUARE FEET(Sqf)</option>
                </select>
              </FormControl>

              <FormControl margin={"10px"}>
                <FormLabel>Select Secondary Unit:</FormLabel>
                <select
                  value={data.selectedSecondaryUnit}
                  onChange={(e)=>setData(pre=>({...pre,selectedSecondaryUnit:e.target.value}))}
                >
                  <option value="">None</option>
                  <option value="bags">BAGS(Bag)</option>
                  <option value="bottle">BOTTLE(Btl)</option>
                  <option value="box">BOX(Box)</option>
                  <option value="bundles">BUNDLES(Bdl)</option>
                  <option value="cans">CANS(can)</option>
                  <option value="cartons">CARTONS(Ctn)</option>
                  <option value="dogens">DOGENS(Dzn)</option>
                  <option value="grammes">GRAMMES(GM)</option>
                  <option value="kilograms">KILOGRAMS(KG)</option>
                  <option value="litters">LITTERS(Ltr)</option>
                  <option value="meaters">MEATERS(Mtr)</option>
                  <option value="mililiter">MILILITER(Ml)</option>
                  <option value="numbers">NUMBERS(Nos)</option>
                  <option value="packs">PACKS(Pac)</option>
                  <option value="pairs">PAIRS(Prs)</option>
                  <option value="pieces">PIECES(Pcs)</option>
                  <option value="quintals">QUINTALS(Qtl)</option>
                  <option value="rolls">ROLLS(Rol)</option>
                  <option value="squarefeet">SQUARE FEET(Sqf)</option>
                </select>
              </FormControl>

              <FormControl margin={"10px"}>
                <FormLabel>Conversion Rate:</FormLabel>
                <Input
                  type="number"
                  placeholder="Conversion Rate"
                  value={data.conversionRate}
                  onChange={(e)=>setData(pre=>({...pre,conversionRate:e.target.value}))}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleCancelUnit}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={()=>handleSaveUnit(data)}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

const Items = () => {
  const dummyCategories = ["Electronics", "Clothing", "Home Decor", "Books"];
  const Company = {
    name: "Company Name",
  };
  const Items = [
    {
      id: 1,
      Name: "name",
      stock: 100,
      price: 1000,
      tax: 0,
      total: 1000,
      buyprice: 200,
      sellprice: 300,
    },
  ];
  const modal1 = useDisclosure();
  const modal2 = useDisclosure();
  const { firmId } = useSelector((store) => store.FirmRegistration);
  const { getStockData } = useSelector((store) => store.stockReducer);
  const Categories = useSelector((store) => store.categoryReducer);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showUnitDialog, setShowUnitDialog] = useState(false);
  const [selectedBasicUnit, setSelectedBasicUnit] = useState("");
  const [selectedSecondaryUnit, setSelectedSecondaryUnit] = useState("");
  const [conversionRate, setConversionRate] = useState(0);
  const [isCreditLimitEnabled, setIsCreditLimitEnabled] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [additionalField1, setAdditionalField1] = useState("");
  const [enableShippingAddress, setEnableShippingAddress] = useState(false);

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setFilteredProducts(getStockData);
    } else {
      const filtered = getStockData.filter((product) => {
        return product.category?.some((category) =>
          selectedCategories.includes(category)
        );
      });
      setFilteredProducts(filtered);
    }
  }, [selectedCategories, getStockData]);

  const dispatch = useDispatch();
  const [form, setForm] = useState({
    itemName: "",
    category: "",
    description: "",
    category: "",
    brand: "",
    stockQuantity: "",
    gstRate: "",
    firmId: "",
    itemHsn: "",
    discOnMrpforsale: "",
    discOnMrpForWholesale: "",
    purchasePrice: "",
    salesPric: "",
    wholesalePrice: "",
    minimumWholesaleQty: "",
    openingQuantity: "",
    minStockToMaintain: "",
    atPrice: "",
    location: "",
    asOfDate: "",
    selectedBasicUnit: "",
    selectedSecondaryUnit: "",
    conversionRate: "",
  });

  const handleEditUnitClick = () => {
    setShowUnitDialog(true);
  };

  const handleBasicUnitChange = (event) => {
    setSelectedBasicUnit(event.target.value);
    // Perform any related actions if needed
  };

  const handleSecondaryUnitChange = (event) => {
    setSelectedSecondaryUnit(event.target.value);
    // Perform any related actions if needed
  };

  const handleConversionRateChange = (event) => {
    setConversionRate(event.target.value);
    // Perform any related actions if needed
  };

  const handleSaveUnit = (data) => {
    // Save the selected units and conversion rate
    setForm(pre=>({...pre,...data}))
    setShowUnitDialog(false);
    // Perform any actions after saving the units and rate
  };

  const handleCancelUnit = () => {
    // Close the unit dialog without saving
    setShowUnitDialog(false);
    // Perform any actions needed after cancel
  };

  useEffect(() => {
    const category = selectedCategories;
    setForm((prevForm) => ({ ...prevForm, category }));
  }, [selectedCategories]);

  const [categoryForm, setCategoryForm] = useState({
    name: "",
    codeType: "default",
    firmId: `${firmId}`,
  });

  const handleCategoryInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryForm({
      ...categoryForm,
      [name]: value,
    });
  };

  const handleChangeItems = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "mrp" || name === "discOnMrpforsale" || name === "discOnMrpForWholesale") {
      const mrp = parseFloat(form.mrp);
      const discountPercentageSale = parseFloat(form.discOnMrpforsale);
      const discountPercentageWholesale = parseFloat(form.discOnMrpForWholesale);
      const salesPrice = mrp - (mrp * discountPercentageSale) / 100;
      const wholesalePrice = mrp - (mrp * discountPercentageWholesale) / 100;
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
        salesPrice: salesPrice.toFixed(2),
        wholesalePrice: wholesalePrice.toFixed(2),
      }));
    } else {
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const userDetails = JSON.parse(sessionStorage.getItem("companyDetails"))
    ? JSON.parse(sessionStorage.getItem("companyDetails"))
    : null;
  const inputNameArray = [
    "itemName",
    "description",
    "category",
    "brand",
    "stockQuantity",
    "gstRate",
    "firmId",
    "itemHsn",
    "discOnMrpforsale",
    "discOnMrpForWholesale",
    "purchasePrice",
    "salesPrice",
    "wholesalePrice",
    "minimumWholesaleQty",
    "openingQuantity",
    "minStockToMaintain",
    "atPrice",
    "location",
    "asOfDate",
    "selectedBasicUnit",
    "selectedSecondaryUnit",
    "conversionRate",
  ];
  const [errors, setErrors] = useState([]);

  const handleItemsAdd = () => {
  
    // if (!Inputvalidate(inputNameArray, form, setErrors)) {
    //   return;
    // }
 let formData = {...form,firmId}
    dispatch(postStockAction(formData, userDetails?.token, firmId,modal1))
      // .then(() => {
      //   console.log("hiii")
      //   // After successful addition, refetch data
      //   dispatch(getStockAction(userDetails?.token, firmId));
      //   dispatch(getCategoriesAction(userDetails?.token, firmId));
      //   modal1.onClose();
      // })
      // .catch((error) => {
      //   console.error(error);
      // });
      
  };

  useEffect(() => {
    dispatch(getStockAction(userDetails?.token, firmId));
    dispatch(getCategoriesAction(userDetails?.token, firmId));
  }, [firmId]);

  const handleAddCategory = () => {
    // Logic to add the new category
    dispatch(
      postCategoryAction(categoryForm, userDetails?.token, firmId, modal2)
    );
  };

  const handleCategoryChange = (categoryName) => {
    if (selectedCategories.includes(categoryName)) {
      // If category is already selected, remove it
      setSelectedCategories(selectedCategories.filter((name) => name !== categoryName));
    } else {
      // If category is not selected, add it
      setSelectedCategories([...selectedCategories, categoryName]);
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteStockAction(userDetails?.token, id))
      .then(() => {
        // After successful deletion, refetch data
        dispatch(getStockAction(userDetails?.token, firmId));
        dispatch(getCategoriesAction(userDetails?.token, firmId));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChangeParty = (e) => {
    // Handle the change event for party inputs
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleCheck1 = () => {
    setChecked1(!checked1);
  };

  const handleCheck2 = () => {
    setChecked2(!checked2);
  };

  const handleCheck3 = () => {
    setChecked3(!checked3);
  };

  const handleCheck4 = () => {
    setChecked4(!checked4);
  };

  const handleAdditionalInput1 = (e) => {
    setAdditionalField1(e.target.value);
  };

  return (
    <>
      <Company_name company_name={Company.name} />
      <Flex>
        <Slidebar />
        <Box margin={"auto"} marginTop="20px" overflow={"hidden"} width="80%">
          {/* buttons */}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {/* select categories */}
            <Menu>
              <MenuButton as={Button} backgroundColor='gray.100' margin="10px" px="4"
                rightIcon={<ChevronDownIcon />}>
                Select Category
              </MenuButton>
              <MenuList>
                {Categories.categories.length > 0 ? Categories?.categories.map((category, id) => (
                  <MenuItem key={id}>
                    <Checkbox
                      onChange={() => handleCategoryChange(category.name)}
                      checked={selectedCategories.includes(category.name)}
                    >
                      {category.name}
                    </Checkbox>
                  </MenuItem>
                )) :
                  <MenuItem>
                    Not Found
                  </MenuItem>
                }
              </MenuList>
            </Menu>
            {/* add new item */}
            <Button
              backgroundColor="blue.400"
              margin={"10px"}
              onClick={modal1.onOpen}
              px="4"
            >
              Add Item +{" "}
            </Button>
            {/* add new category */}
            <Button
              backgroundColor="blue.400"
              margin={"10px"}
              onClick={modal2.onOpen}
              px="4"
            >
              Add Category +{" "}
            </Button>
          </Box>

          {/* table data */}
          <TableContainer style={{ margin: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <Table style={{ width: '100%' }}>
              <Thead>
                <Tr>
                  <Th>itemName</Th>
                  <Th>category</Th>
                  {/* <Th>description</Th> */}
                  {/* <Th>category</Th> */}
                  <Th>brand</Th>
                  <Th>stockQuantity</Th>
                  <Th>gstRate</Th>
                  <Th>firmId</Th>
                  <Th>itemHsn</Th>
                  <Th>discOnMrpforsale</Th>
                  <Th>discOnMrpForWholesale</Th>
                  <Th>purchasePrice</Th>
                  <Th>salesPrice</Th>
                  <Th>wholesalePrice</Th>
                  <Th>minimumWholesaleQty</Th>
                  <Th>openingQuantity</Th>
                  <Th>minStockToMaintain</Th>
                  <Th>atPrice</Th>
                  <Th>location</Th>
                  <Th>asOfDate</Th>
                  <Th>selectedBasicUnit</Th>
                  <Th>selectedSecondaryUnit</Th>
                  <Th>conversionRate</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredProducts?.map((data) => (
                  <Tr key={data._id}>
                    {/* <Td>{data?._id}</Td> */}
                    <Td>{data?.itemName}</Td>
                    <Td>{data?.category}</Td>
                    {/* <Td>{data?.description}</Td> */}
                    {/* <Td>{data?.category}</Td> */}
                    <Td>{data?.brand}</Td>
                    <Td>{data?.stockQuantity}</Td>
                    <Td>{data?.gstRate}</Td>
                    <Td>{data?.firmId}</Td>
                    <Td>{data?.itemHsn}</Td>
                    <Td>{data?.discOnMrpforsale}</Td>
                    <Td>{data?.discOnMrpForWholesale}%</Td>
                    <Td>{data?.purchasePrice}</Td>
                    <Td>{data?.salesPrice}</Td>
                    <Td>{data?.wholesalePrice}</Td>
                    <Td>{data?.minimumWholesaleQty}</Td>
                    <Td>{data?.openingQuantity}</Td>
                    <Td>{data?.minStockToMaintain}</Td>
                    <Td>{data?.atPrice}</Td>
                    <Td>{data?.location}</Td>
                    <Td>{moment(data?.asOfDate).format("DD/MM/YY")}</Td>
                    <Td>{data?.selectedBasicUnit}</Td>
                    <Td>{data?.selectedSecondaryUnit}</Td>
                    <Td>{data?.conversionRate}</Td>
                    <Td display={"flex"} gap={"8px"} align="center">
                      <Link><FaEdit /></Link>
                      <Link>more</Link>
                      <Link onClick={() => { handleDelete(data._id) }}><FaTrash /></Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        {/* Add new item modal */}
        <Modal isOpen={modal1.isOpen} onClose={modal1.onClose} size='4xl'>
          <ModalOverlay />
          <ModalContent maxWidth='60%'>
            <ModalHeader>Add New Item</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* item details */}
              <Flex>
                <FormControl margin={"10px"}>
                  <FormLabel>Category :</FormLabel>
                  <Select
                    name="category"
                    value={form?.category}
                    onChange={handleChangeItems}
                  >
                    <option value={""}>Select Category</option>
                    <option value={"electronics"}>Elctronices</option>
                    <option value={"cloths"}>Cloths</option>
                    <option value={"bori"}>Bori</option>
                    <option value={"furnature"}>Furnature</option>
                    <option value={"foods"}>Foods</option>
                    <option value={"hardware"}>Hardware</option>
                    <option value={"medical"}>Medical</option>
                    <option value={"mobile"}>Mobile</option>
                  </Select>
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>Item Name :</FormLabel>
                  <Input
                    type="text"
                    placeholder="Item Name"
                    value={form?.itemName}
                    name="itemName"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>ItemHsn:</FormLabel>
                  <Input
                    type="number"
                    placeholder="ItemHsn"
                    value={form.itemHsn}
                    name="itemHsn"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>Brand :</FormLabel>
                  <Input
                    type="text"
                    placeholder="Brand"
                    value={form.brand}
                    name="brand"
                    onChange={handleChangeItems}
                  />
                </FormControl>
              </Flex>
              <Flex>
                <FormControl margin={"10px"}>
                  <Button onClick={handleEditUnitClick}>Edit Unit</Button>
                </FormControl>
              <UnitDialog
                showUnitDialog={showUnitDialog}
                handleEditUnitClick={handleEditUnitClick}
                handleBasicUnitChange={handleBasicUnitChange}
                handleSecondaryUnitChange={handleSecondaryUnitChange}
                handleConversionRateChange={handleConversionRateChange}
                handleSaveUnit={handleSaveUnit}
                handleCancelUnit={handleCancelUnit}
                selectedBasicUnit={selectedBasicUnit}
                selectedSecondaryUnit={selectedSecondaryUnit}
                conversionRate={conversionRate}
              />
              </Flex>
              <Box mt='30px'>
                <Tabs>
                  <TabList>
                    <Tab fontWeight='500'>Pricing</Tab>
                    <Tab fontWeight='500'>Stock</Tab>
                    <Tab fontWeight='500'>Manufacturing</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <Box display='flex'>
                        <Flex direction='column' gap={5}>
                        <FormControl margin={"10px"}>
                  <FormLabel>MRP:</FormLabel>
                  <Input
                    type="number"
                    placeholder="MRP"
                    value={form.mrp}
                    name="mrp"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>Disc.On MRP For Sale (%):</FormLabel>
                  <Input
                    type="number"
                    placeholder="Disc.On MRP For Sale(%)"
                    value={form.discOnMrpforsale}
                    name="discOnMrpforsale"
                    onChange={handleChangeItems}
                    style={form.price < form.cost ? { border: "1px solid red" } : {}}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>Disc. on MRP For WholeSale(%) :</FormLabel>
                  <Input
                    type="number"
                    placeholder="Disc. on MRP For wholeSale(%)"
                    value={form.discOnMrpForWholesale}
                    name="discOnMrpForWholesale"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>Purchase Price:</FormLabel>
                  <Input
                    type="number"
                    placeholder="Purchase Price"
                    value={form.purchasePrice}
                    name="purchasePrice"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                        </Flex>

                  <Flex direction='column' gap={5}>
                  <FormControl margin={"10px"}>
                  <FormLabel>Sales Price :</FormLabel>
                  <Input
                    type="number"
                    placeholder="Sales Price"
                    value={form.salesPrice}
                    name="salesPric"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>Wholesale Price :</FormLabel>
                  <Input
                    type="number"
                    placeholder="Wholesale Price"
                    value={form.wholesalePrice}
                    name="wholesalePrice"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>Minimum Wholesale Qty:</FormLabel>
                  <Input
                    type="number"
                    placeholder="Minimum Wholesale Qty"
                    value={form.minimumWholesaleQty}
                    name="minimumWholesaleQty"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel> GST Rate:</FormLabel>
                  <Select
                    type="number"
                    placeholder="select gst Rate"
                    onChange={handleChangeItems}
                    value={form.gstRate}
                    name="gstRate"
                  >
                    
                    <option value=''>None</option>
                     <option value='0'>GST@0</option>
                     <option value='0'>IGST@0</option>
                     <option value='0.25'>IGST@0.25%</option>
                     <option value='0.25'>GST@0.25%</option>
                     <option value='3'>IGST@3%</option>
                     <option value='3'>GST@3%</option>
                     <option value='5'>IGST@5%</option>
                     <option value='5'>GST@5%</option>
                     <option value='12'>IGST@12%</option>
                     <option value='12'>GST@12%</option>
                     <option value='18'>IGST@18%</option>
                     <option value='18'>GST@18%</option>
                     <option value='28'>IGST@28%</option>
                     <option value='28'>GST@28%</option>
                     <option value='exmpt'>exmpt</option>
                  </Select>
                </FormControl>
                </Flex>
                      </Box>
                    </TabPanel>

                    <TabPanel>
                      <Box display='flex' >
                        <Box width='250px' display='flex' flexDirection='column'>
                        <FormControl margin={"10px"}>
                  <FormLabel>Opening Qty:</FormLabel>
                  <Input
                    type="number"
                    placeholder="Opening Quantity"
                    value={form.openingQuantity}
                    name="openingQuantity"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>Min.StockMaintain:</FormLabel>
                  <Input
                    type="number"
                    placeholder="Min Stock to Maintain "
                    value={form.minStockToMaintain }
                    name="minStockToMaintain"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                          { }
                        </Box>
                        <Box width='250px' ml='50px'>
                        <FormControl margin={"10px"}>
                  <FormLabel>At Price :</FormLabel>
                  <Input
                    type="number"
                    placeholder="At Price"
                    value={form.atPrice}
                    name="atPrice"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                <FormControl margin={"10px"}>
                  <FormLabel>Location :</FormLabel>
                  <Input
                    type="text"
                    placeholder="Location "
                    value={form.location }
                    name="location"
                    onChange={handleChangeItems}
                  />
                </FormControl>
                        </Box>
                        <FormControl margin={"10px"}>
                  
                        <Box width='250px' ml='50px'>
                        <FormLabel>AsOfDate :</FormLabel>
                          <Input
                          placeholder="AsOfDate "
                            value={form.asOfDate}
                            name="asOfDate"
                            onChange={handleChangeParty} type="date" />
                        </Box>
                        </FormControl>
                      </Box>
                    </TabPanel>

                    {/* <TabPanel>
                      <Box
                        display='flex' flexDirection='column'
                      >
                        <Box width='550px'>
                          <Box display='flex' gap={5}>
                            <Checkbox checked={checked1}
                              onChange={handleCheck1} size='lg' />
                            <Input onChange={(e) => handleAdditionalInput1(e)} value={additionalField1} placeholder="Additional Field 1" />
                            {checked1 ? <Input placeholder={additionalField1 ? `Enter value of ${additionalField1}` : ""} /> : ""}
                          </Box>

                          <Box mt='8px' display='flex' gap={5}>
                            <Checkbox checked={checked2}
                              onChange={handleCheck2} size='lg' />
                            <Input placeholder="Additional Field 2" />
                            {checked2 ? <Input placeholder="Additional Field 1" /> : ""}
                          </Box>

                          <Box mt='8px' display='flex' gap={5}>
                            <Checkbox checked={checked3}
                              onChange={handleCheck3} size='lg' />
                            <Input placeholder="Additional Field 3" />
                            {checked3 ? <Input placeholder="Additional Field 1" /> : ""}
                          </Box>


                          <Box mt='8px' display='flex' gap={5}>
                            <Checkbox checked={checked4}
                              onChange={handleCheck4} size='lg' />
                            <Input placeholder="Additional Field 4" />
                            {checked4 ? <Input placeholder="Additional Field 4" /> : ""}
                          </Box>

                        </Box>
                      </Box>
                    </TabPanel> */}
                  </TabPanels>
                </Tabs>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={modal1.onClose}>
                Close
              </Button>
              <Button colorScheme="red" onClick={handleItemsAdd}>
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Add category modal */}
        <Modal isOpen={modal2.isOpen} onClose={modal2.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Select Category */}
              <Select
                backgroundColor='gray.100'
                rightIcon={<ChevronDownIcon />}
                defaultValue='default'
                placeholder="Select category"
                name="name"
                value={categoryForm.name}
                onChange={handleCategoryInputChange}
              >
                {dummyCategories.map((category) => (
                  <option key={category}>{category}</option>
                ))}
              </Select>

              <Text mt="1rem" mb="0.5rem" textAlign='center'>OR</Text>

              {/* Add new category */}
              <FormControl>
                <FormLabel> New Category</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter New Category"
                  name="name"
                  value={categoryForm.name}
                  onChange={handleCategoryInputChange}
                />
              </FormControl>

{/* Select HSN/SSN code */}
<Select
  rightIcon={<ChevronDownIcon />}
  defaultValue='default'
  placeholder="Select HSN/SSN code"
  mt='8'
  name="codeType"
  value={categoryForm.codeType}
  onChange={handleCategoryInputChange}
>
  {/* Options for HSN code */}
  <optgroup label="HSN Code">
    <option value="hsn_code_1">HSN Code 1</option>
    <option value="hsn_code_2">HSN Code 2</option>
  </optgroup>

  {/* Options for SSN code */}
  <optgroup label="SSN Code">
    <option value="ssn_code_1">SSN Code 1</option>
    <option value="ssn_code_2">SSN Code 2</option>
  </optgroup>
</Select>
</ModalBody>

<ModalFooter>
<Button colorScheme="blue" mr={3} onClick={modal2.onClose}>
  Close
</Button>
<Button colorScheme="green" onClick={handleAddCategory}>
  Add
</Button>
</ModalFooter>
</ModalContent>
</Modal>
</Flex>
</>
);
};

export default Items;