import React, { useState } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  IconButton,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

function DailyWorking(){

      const userData=[
        {_id:1, name:"pawan", companyName:"redmi", address:"Jharkhand", mobileNo:1234567890, email:"company@gmail.com", joining:20},
        {_id:2, name:"pawan", companyName:"redmi", address:"Jharkhand", mobileNo:1234567890, email:"company@gmail.com", joining:20},
        {_id:3, name:"pawan", companyName:"redmi", address:"Jharkhand", mobileNo:1234567890, email:"company@gmail.com", joining:20},
        {_id:4, name:"pawan", companyName:"redmi", address:"Jharkhand", mobileNo:1234567890, email:"company@gmail.com", joining:20},
        {_id:5, name:"pawan", companyName:"redmi", address:"Jharkhand", mobileNo:1234567890, email:"company@gmail.com", joining:20}
      ]
    
      const itemsPerPage = 4;
      const [currentPage, setCurrentPage] = useState(1);
      const totalItems = userData?.length;
      const totalPages = Math.ceil(totalItems / itemsPerPage);
    
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
    
      const currentItems = userData?.slice(startIndex, endIndex);
    
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };
    
      return (
        <Box bg="gray.100" p="4"  m='4'>
          <Card bg="white" boxShadow="lg" borderRadius="md" p="8">
            <Heading as="h1" mb="4" align="left" mt={4} pl={4} color='gray.600'>
              Daily working report 
            </Heading>
            <Divider mb={4} style={{ opacity: '0.2' }} />
            <Table variant="simple" mt="4">
              <TableCaption>
                Showing {startIndex + 1} - {Math.min(endIndex, totalItems)} of {totalItems} entries
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>User Name</Th>
                  <Th>Company Name</Th>
                  <Th>Address</Th>
                  <Th>Mobile</Th>
                  <Th>Email</Th>
                  <Th>Joining Possibility by %</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems.map((item) => (
                  <Tr key={item._id}>
                    <Td>{item.name}</Td>
                    <Td>{item.companyName}</Td>
                    <Td>{item.address}</Td>
                    <Td>{item.mobileNo}</Td>
                    <Td>{item.email}</Td>
                    <Td>{item.joining} %</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Box mt="4" display="flex" justifyContent="space-between">
              <Button
                leftIcon={<ChevronLeftIcon />}
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </Button>
              <Button
                rightIcon={<ChevronRightIcon />}
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Button>
            </Box>
          </Card>
        </Box>
      );
}
export default DailyWorking