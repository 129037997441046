import axios from "axios";
import { ERROR_LOGIN, LOADING_LOGIN, SUCCESS_LOGIN } from "./Signin.Type";
import { LIVE_URL2, baseUrl } from "../config/Commen";
import { toast } from "react-toastify";

export const LoginAction = (creds, navigate) => (dispatch) => {
  // dispatch({ type: LOADING_LOGIN });

  // axios
  //   .post(`${LIVE_URL2}/signin`, creds)
  //   .then((res) => {
  //     dispatch({ type: SUCCESS_LOGIN, payload: res });
  //     if (res.status === 200) {
  //       toast.success("Login success");

  //       navigate("/Inventory_Software");
  //     }
  //   })
  //   .catch((err) => {
  //     dispatch({ type: ERROR_LOGIN, payload: err });
  //     if (err.response.data?.message) {
  //       toast.error(err.response.data?.message);
  //     }
  //     // console.log(err);
  //   });
};

export const handleVendorLogin = (data, navigate) => (dispatch) => {
  //dispatch({ type: LOADING_LOGIN });

  axios
    .post(`${baseUrl}/CA/Vendor/logIn`, data)
    .then((res) => {
      console.log("vendorLogin", res.data);
      //dispatch({ type: SUCCESS_LOGIN, payload: res.data });
      sessionStorage.setItem("role","vendor")
      localStorage.setItem('token', res.data.accessToken);
      sessionStorage.setItem("userDetails" ,JSON.stringify(res.data.vendor))
      toast.success("WelCome, You Are Login Successfully!");
      navigate("/vendor-profile");
    })
    .catch((err) => {
      console.log(err);
      //dispatch({ type: ERROR_LOGIN, payload: err });
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      } else {
        toast.error("Error, Something Went Wrong!");
      }
    });
};


export const handleUserLogin = (data, navigate) => (dispatch) => {
  //dispatch({ type: LOADING_LOGIN });

  axios
    .post(`${baseUrl}/CA/User/logIn`, data)
    .then((res) => {
      console.log("userLogin", res.data);
      //dispatch({ type: SUCCESS_LOGIN, payload: res.data });
      sessionStorage.setItem("role","user")
      localStorage.setItem('token', res.data.accessToken);
      sessionStorage.setItem("userDetails" ,JSON.stringify(res.data.vendor))
      toast.success("WelCome, You Are Login Successfully!");
      navigate("/user-profile");
    })
    .catch((err) => {
      console.log(err);
      //dispatch({ type: ERROR_LOGIN, payload: err });
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      } else {
        toast.error("Error, Something Went Wrong!");
      }
    });
};