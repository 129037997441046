import React from 'react'
import Top_Ngo1 from '../Top/Top_Ngo1'
import Home5 from '../Home5/Home5'
import Home6 from '../Home6'
import Navbar_AL from '../../Navbar_AL'
import Footer from '../../Footer'

const Society_registration = () => {
    return (
        <> 
            <Navbar_AL/>
            <Top_Ngo1 />
            <Home5 />
            <Home6 />
            <Footer/>
        </>
    )
}

export default Society_registration