import React from 'react'
import Top2 from '../Top/Top2'
import Home5 from "../Home5/Home5"
import Home6 from "./Home6"
import Navbar_AL from '../../Navbar_AL'
import Footer from '../../Footer'

const Accounting_Booking = () => {
    return (
        <div>
            <Navbar_AL/>
            <Top2 heading=" Accounting and
Bookkeeping Services"  text1="Now save upto 90% on your accountant fee. Avail accounting and bookkeeping services for a whole year at just ₹12999!" text2="Bookkeeping of assets, sales, purchases, and expenses" />
            <Home5></Home5>
            <Home6></Home6>
            <Footer/>
        </div>
    )
}

export default Accounting_Booking
