


export const LIVE_URL = "https://tax-service.onrender.com"
export const LIVE_URL2  = "https://ca-api-9yu6.onrender.com"
export const  localurl = "http://localhost:8001"

export const baseUrl="https://ca-website-by-paresh.onrender.com"

// export const userDetails = JSON.parse(sessionStorage.getItem("userDetails")) ?? null;

export const  userDetails =  JSON.parse(sessionStorage.getItem("companyDetails")) ?JSON.parse(sessionStorage.getItem("companyDetails")):null
