import { Box, GridItem, Grid, Text, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "../styles/NavbarStyles/BusinessSetup.css";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronUp, FaFileSignature, FaRegAddressCard, FaSearch } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const BusinessSetup = () => {
  const [isActive, setIsActive] = useState(false);
  const [downArrow, setDownArrow] = useState(false);
  const [hoverbox, setHoverbox] = useState(1);

  const toggleFunc = () => {
    setIsActive((prev) => !prev);
  };

  const ChangeActive = () => {
    setIsActive(false);
  };

  return (
    <Box>
      <div
        onMouseEnter={() => setDownArrow(true)}
        onMouseLeave={() => setDownArrow(false)}
        className="Trademark_Menu"
        onClick={() => toggleFunc()}

        style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"7px"}}
      >
        BUSINESS SETUP
        {isActive? <span><FaChevronUp size={"18px"}/></span>:<span><FaChevronDown size={"18px"}/></span>}
        
      </div>
      {isActive ? (
        <Box
          mt="100px"
          className="hover_Box_Container1"
          onMouseLeave={() => ChangeActive()}
          backgroundColor={"gray.100"}
        >
          <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={"20px"}
          >
            <Flex
              direction={"column"}
              gap={"10px"}
              w={"50%"}
              textAlign={"left"}
            >
              <Box
                fontSize={"130%"}
                fontWeight={"bold"}
                color={"gray.500"}
                p={"10px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                _hover={{ background: "#FFFFFF", color: "black" }}
                borderRadius={"5px"}
                onMouseEnter={() => setHoverbox(1)}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <FaFileSignature />
                  <Box>Business Registration</Box>
                </Box>
                <Box>
                  <FaChevronRight />
                </Box>
              </Box>
              <Box
                fontSize={"130%"}
                fontWeight={"bold"}
                color={"gray.500"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                _hover={{ background: "#FFFFFF", color: "black" }}
                p={"10px"}
                borderRadius={"5px"}
                onMouseEnter={() => setHoverbox(2)}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <FaSearch />

                  <Box>Company Name Search</Box>
                </Box>
                <Box>
                  <FaChevronRight />
                </Box>
              </Box>
              <Box
                fontSize={"130%"}
                fontWeight={"bold"}
                color={"gray.500"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                _hover={{ background: "#FFFFFF", color: "black" }}
                p={"10px"}
                onMouseEnter={() => setHoverbox(3)}
                borderRadius={"5px"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <FaRegAddressCard />
                  <Box>Licenses & Registrations</Box>
                </Box>
                <Box>
                  <FaChevronRight />
                </Box>
              </Box>
            </Flex>
            <Box w={"50%"} textAlign={"left"} p={"0px 20px"}>
              <Box
                display={hoverbox == 1 ? "block" : "none"}
                backgroundColor={"#FFFFFF"}
                borderRadius={"5px"}
                p={"10px 20px"}
              >
                <Text fontSize={"18px"} color={"black"}>
                  Business Registration
                </Text>
                <hr style={{ marginTop: "-15px", marginBottom: "10px" }} />
                <Link to="/private_limited_company">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Private Limited Company
                  </Text>
                </Link>
                <Link to="/limited_liability_partnership">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Limited Liability Partnership
                  </Text>
                </Link>
                <Link to="/one_persone_company">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    One Person Company
                  </Text>
                </Link>
                <Link to="/sole_proprietorship">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Sole Proprietorship
                  </Text>
                </Link>
                <Link to="/nidhi_company">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Nidhi Company
                  </Text>
                </Link>
                <Link to="/producer_company">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Producer Company
                  </Text>
                </Link>
                <Link to="/partnership_firm">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Partnership Firm
                  </Text>
                </Link>
              </Box>

              <Box
                display={hoverbox == 2 ? "block" : "none"}
                backgroundColor={"#FFFFFF"}
                borderRadius={"5px"}
                p={"10px 20px"}
              >
                <Text fontSize={"18px"} color={"black"}>
                  Company Name Search
                </Text>
                <hr style={{ marginTop: "-15px", marginBottom: "10px" }} />
                <Link to="/company_name_search">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Company Name Search
                  </Text>
                </Link>
              </Box>

              <Box
                display={hoverbox == 3 ? "block" : "none"}
                backgroundColor={"#FFFFFF"}
                borderRadius={"5px"}
                p={"10px 20px"}
              >
                <Text fontSize={"18px"} color={"black"}>
                  Licenses & Registrations
                </Text>
                <hr style={{ marginTop: "-15px", marginBottom: "10px" }} />
                <Link to="/digital_signature">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Digital Signature Certificate
                  </Text>
                </Link>
                <Link to="/apply_msme">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    MSME/SSI Registration
                  </Text>
                </Link>
                <Link to="/iso_certification">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    ISO Certification
                  </Text>
                </Link>
                <Link to="/import_export">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    IEC [Import/Export Code]
                  </Text>
                </Link>
                <Link to={"/cfss"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    CFSS
                  </Text>
                </Link>
                {/* <Link to="legal_metrology" ><Text onClick={() => ChangeActive()} className="hoverBoxHeadingText_container_child">Legal Metrology</Text></Link> */}
                {/* <Link to="hallmark_registration" ><Text onClick={() => ChangeActive()} className="hoverBoxHeadingText_container_child">Hallmark Registration</Text></Link> */}
                <Link to="/bis_registration">
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    BIS Registration
                  </Text>
                </Link>
              </Box>
            </Box>

            {/* <GridItem className="grid_item_in_hovered_div">
                               
                               
                          
                            </GridItem> */}
            {/* <GridItem className="grid_item_in_hovered_div"> */}

            {/* <Box className="hoverBoxHeadingText">
                                    Web Development
                                </Box>
                                <Box className="hoverBoxHeadingText_container">
                                    <Link to="wesite_development" ><Text onClick={() => ChangeActive()} className="hoverBoxHeadingText_container_child">Web/E-Commerce Website Development</Text></Link>
                                </Box> */}
            {/* <Box className="hoverBoxHeadingText">
                                    International Business Setup
                                </Box>
                                <Box className="hoverBoxHeadingText_container">
                                    <Link to="usa_incorporation"><Text onClick={() => ChangeActive()} className="hoverBoxHeadingText_container_child">US Incorporation</Text></Link>
                                    <Link to="singapore_incorporation"><Text onClick={() => ChangeActive()} className="hoverBoxHeadingText_container_child">Singapore Incorporation</Text></Link>
                                </Box> */}
            {/* </GridItem> */}
          </Flex>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export { BusinessSetup };
