import { Box, Input, Text, Button, Image, Heading } from "@chakra-ui/react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    handleGetUser,
  handleGetVendor,
  handleUpdateUserProfile,
  handleUpdateVendorProfile,
} from "../../../Redux/Profile/Profile.Action";
import { useEffect, useState } from "react";

function UserUpdateProfile() {
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getUserData } = useSelector((store) => store.Profile);

  useEffect(() => {
    dispatch(handleGetUser);
  }, []);

  useEffect(() => {
    setProfileData({
      name: getUserData?.Name,
      email: getUserData?.email,
      companyName: getUserData?.companyName,
      aadharNumber: getUserData?.aadharNumber,
      phoneNumber: getUserData?.mobileNo,
      panNo: getUserData?.panNumber,
      city: getUserData?.city,
      state: getUserData?.state,
      image: null,
    });
  }, [getUserData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileData({ ...profileData, image: file });
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    setIsEditing(false);

    const formData = new FormData();
    formData.append("name", profileData.name);
    formData.append("email", profileData.email);
    formData.append("companyName", profileData.companyName);
    formData.append("aadharNumber", profileData.aadharNumber);
    formData.append("phoneNumber", profileData.phoneNumber);
    formData.append("panNo", profileData.panNo);
    formData.append("city", profileData.city);
    formData.append("state", profileData.state);
    formData.append("image", profileData.image);

    dispatch(handleUpdateUserProfile(formData));
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  console.log("getUserDataPro", getUserData);
  return (
    <Box>
      <Header />
      <Box bg="gray.100" p="4" m="4">
        <Box bg="white" boxShadow="lg" borderRadius="md" p="8">
          <Box>
            <Box
              display={"flex"}
              justifyContent={"space-evenly"}
              alignItems={"center"}
            >
              <Box w={"80px"} h={"80px"}>
                <Image
                  w={"100%"}
                  h={"100%"}
                  borderRadius={"50%"}
                  src={
                    getUserData?.image || "https://via.placeholder.com/150"
                  }
                  alt="Profile"
                />
              </Box>

              <Heading fontFamily={"serif"}>
                {getUserData?.Name} Profile
              </Heading>
              <Box>
                <Button
                  colorScheme="white"
                  bg={"#FFB91D"}
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
                <Button
                  colorScheme="white"
                  bg={"#FFB91D"}
                  ml={"20px"}
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
              </Box>
            </Box>

            <Box
              w={"70%"}
              m={"auto"}
              mt={"50px"}
              display={"grid"}
              gridTemplateColumns={"repeat(2, 1fr)"}
              alignItems={"center"}
              gap={"20px"}
            >
              <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>Name</Text>
                <Input
                  mt={"-10px"}
                  name="name"
                  value={profileData?.name}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              </Box>
              <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>Email</Text>

                <Input
                  mt={"-10px"}
                  name="email"
                  value={profileData?.email}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              </Box>
              <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>Company Name</Text>

                <Input
                  mt={"-10px"}
                  name="companyName"
                  value={profileData?.companyName}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              </Box>
              <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>Aadhar Number</Text>

                <Input
                  mt={"-10px"}
                  name="aadharNumber"
                  value={profileData?.aadharNumber}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              </Box>
              <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>Mobile Number</Text>

                <Input
                  mt={"-10px"}
                  name="phoneNumber"
                  value={profileData?.phoneNumber}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              </Box>
              <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>Pan Number</Text>

                <Input
                  mt={"-10px"}
                  type="text"
                  name="panNo"
                  value={profileData?.panNo}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              </Box>

              {/* <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>Address</Text>

                <Input
                  mt={"-10px"}
                  type="text"
                  name="address"
                  value={profileData?.address}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              </Box> */}

              <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>City</Text>

                <Input
                  mt={"-10px"}
                  type="text"
                  name="city"
                  value={profileData?.city}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              </Box>

              <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>State</Text>

                <Input
                  mt={"-10px"}
                  type="text"
                  name="state"
                  value={profileData?.state}
                  readOnly={!isEditing}
                  onChange={handleChange}
                />
              </Box>
              <Box textAlign={"left"} fontWeight={"bold"}>
                <Text>Image</Text>

                <Input
                  mt={"-10px"}
                  p={"5px"}
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  disabled={!isEditing}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UserUpdateProfile;
