import React from 'react'
import Top_pitcher_deck from '../Pitch Deck/component/component/Top_pitch_deck'
import Home6 from '../Pitch Deck/component/component/Home6'
import Home5 from '../Home5/Home5'
import Navbar_AL from '../../Navbar_AL'
import Footer from '../../Footer'

const Pitch_deck = () => {
    return (
        <>
            <Navbar_AL/>
            <Top_pitcher_deck />

            <Home5 />
            <Home6 />
            <Footer/>
        </>
    )
}

export default Pitch_deck