import {
  Box,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Text,
} from "@chakra-ui/react";
import { FiBell } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { RiLockPasswordFill, RiProfileFill } from "react-icons/ri";
import { BsCheckCircle, BsPersonFillCheck } from "react-icons/bs";
import { MdEditDocument } from "react-icons/md";
import { useEffect, useState } from "react";
import { IoPersonCircleSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import { handleGetVendor } from "../../../Redux/Profile/Profile.Action";

function Header() {
  const isVerified = true;
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const {getVendorData}=useSelector((store)=>store.Profile)

  // const userDetails=JSON.parse(sessionStorage.getItem("userDetails"))

  useEffect(()=>{
    dispatch(handleGetVendor)
  },[])
  
  console.log("getVendorDataPro", getVendorData)

  return (
    <Box bg="#FFB91D" px="3" color="white">
      <Flex alignItems="center" p="1" justifyContent="space-between">
        <Box>
          <Flex alignItems="center">
           
              <Heading as="h1" ml="8">
                Vendor Profile
              </Heading>
            
            {getVendorData?.accountStatus=="Approved" && (
              <Box ml="2" color="blue.500" fontWeight="bold" fontSize="1rem">
                <BsCheckCircle />
              </Box>
            )}
          </Flex>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems="center">
          <Box fontWeight={"bold"}>{getVendorData?.Name}</Box>
          <Menu>
            <MenuButton as={Avatar} src={getVendorData?.image} mx="8" cursor="pointer" />
            <MenuList bg="#FFB91D" color="white">
              {/* <MenuItem bg="#FFB91D">
                <IoPersonCircleSharp size={"18px"} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  <Link to="/vendor-profile">My Profile</Link>
                </span>
              </MenuItem> */}
              <MenuItem bg="#FFB91D">
                <BsPersonFillCheck size={"18px"} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  <Link to="/vendor-update-profile">Update Profile</Link>
                </span>
              </MenuItem>
              <MenuItem bg="#FFB91D">
                <RiLockPasswordFill size={"18px"} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  <Link to="/vendor-change-password">Change Password</Link>
                </span>
              </MenuItem>
              {getVendorData?.accountStatus=="Approved" && (<MenuItem bg="#FFB91D">
                <MdEditDocument size={"18px"} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  <Link to="/vendor-complain">Complaints</Link>
                </span>
              </MenuItem>)}
              {getVendorData?.accountStatus=="Approved" && (<MenuItem bg="#FFB91D">
                <MdEditDocument size={"18px"} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  <Link to="/vendor-feedback">Feedback / Advice</Link>
                </span>
              </MenuItem>)}
              <MenuItem
                alignItems="center"
                bg="#FFB91D"
                onClick={() => {
                  sessionStorage.clear();
                  navigate("/");
                }}
              >
                <FiLogOut size={"16px"} />
                <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  Logout
                </span>
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </Box>
  );
}
export default Header;
