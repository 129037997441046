import "../../../styles/BussinessSetup/PrivateLimitedCompany.css";
import Home6 from "./Home6";
import Home5 from "./Home5";
import { HeaderSection } from "./HeaderSection";
import Navbar_AL from "../../Navbar_AL";
import Footer from "../../Footer";


const Apply_MSME_UdyamRegistrationOnline = () => {
    return (
        <>
            <Navbar_AL/>
            <HeaderSection />
            <Home5 />
            <Home6 />
            <Footer/>
        </>
    );
};

export { Apply_MSME_UdyamRegistrationOnline };