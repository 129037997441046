// {import React, { useState } from "react";

// const ToggleSwitch = () => {
//     const [isBlur, setIsBlur] = useState(false);

//     const handleToggle = () => {
//         setIsBlur(!isBlur);
//     };

//     return (
//         <div style={{ filter: isBlur ? "blur(2px)" : "none" }}>
//             <label className="switch">
//                 <input type="checkbox" onChange={handleToggle} />
//                 <span className="slider round"></span>
//             </label>
//         </div>
//     );
// };

// export default ToggleSwitch;}


