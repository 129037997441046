// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg width=%278%27 height=%2714%27 viewBox=%270 0 8 14%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M0 12L5 7L0 2L1 0L8 7L1 14L0 12Z%27 fill=%27%23EEEEEE%27/%3E%3C/svg%3E%0A", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_fir__Tyorp{\n    list-style: disc inside url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    text-align: left;\n    margin-left: 10px;\n    font-family: 'Poppins', sans-serif;\n}\n.footer_fir__Tyorp>li{\n    margin-top: 10px;\n}", "",{"version":3,"sources":["webpack://./src/styles/footer.module.css"],"names":[],"mappings":"AAAA;IACI,+DAAiO;IACjO,gBAAgB;IAChB,iBAAiB;IACjB,kCAAkC;AACtC;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".fir{\n    list-style: disc inside url(\"data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 12L5 7L0 2L1 0L8 7L1 14L0 12Z' fill='%23EEEEEE'/%3E%3C/svg%3E%0A\");\n    text-align: left;\n    margin-left: 10px;\n    font-family: 'Poppins', sans-serif;\n}\n.fir>li{\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fir": "footer_fir__Tyorp"
};
export default ___CSS_LOADER_EXPORT___;
