import { Box, GridItem, Grid, Text, Flex } from "@chakra-ui/react";
import { useState } from "react";
import "../styles/NavbarStyles/BusinessSetup.css";
import { Link } from "react-router-dom";
import { FaChevronDown, FaChevronRight, FaChevronUp, FaRegistered } from "react-icons/fa";
import { HiBuildingOffice2 } from "react-icons/hi2";

const NGO = () => {
  const [isActive, setIsActive] = useState(false);
  const [downArrow, setDownArrow] = useState(false);
  const [hoverbox, setHoverbox] = useState(1);

  const toggleFunc = () => {
    setIsActive((prev) => !prev);
  };

  const ChangeActive = () => {
    setIsActive(false);
  };

  return (
    <Box>
      <Box
        onMouseEnter={() => setDownArrow(true)}
        onMouseLeave={() => setDownArrow(false)}
        className="Trademark_Menu"
        onClick={() => toggleFunc()}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "7px",
        }}
      >
        NGO
        {isActive ? (
          <span>
            <FaChevronUp size={"18px"} />
          </span>
        ) : (
          <span>
            <FaChevronDown size={"18px"} />
          </span>
        )}
      </Box>
      {isActive ? (
        <Box
          mt="100px"
          className="hover_Box_Container1"
          onMouseLeave={() => ChangeActive()}
          backgroundColor={"gray.100"}
        >
          <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={"20px"}
          >
            <Flex
              direction={"column"}
              gap={"10px"}
              w={"50%"}
              textAlign={"left"}
            >
              <Box
                fontSize={"130%"}
                fontWeight={"bold"}
                color={"gray.500"}
                p={"10px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                _hover={{ background: "#FFFFFF", color: "black" }}
                borderRadius={"5px"}
                onMouseEnter={() => setHoverbox(1)}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <FaRegistered />
                  <Box>Registration</Box>
                </Box>
                <Box>
                  <FaChevronRight />
                </Box>
              </Box>
              <Box
                fontSize={"130%"}
                fontWeight={"bold"}
                color={"gray.500"}
                p={"10px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                _hover={{ background: "#FFFFFF", color: "black" }}
                borderRadius={"5px"}
                onMouseEnter={() => setHoverbox(2)}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                   <HiBuildingOffice2 />
                  <Box>NGO Compliance</Box>
                </Box>
                <Box>
                  <FaChevronRight />
                </Box>
              </Box>
            </Flex>
            <Box w={"50%"} textAlign={"left"} p={"0px 20px"}>
              <Box
                display={hoverbox == 1 ? "block" : "none"}
                backgroundColor={"#FFFFFF"}
                borderRadius={"5px"}
                p={"10px 20px"}
              >
                <Text fontSize={"18px"} color={"black"}>
                 Registration
                </Text>
                <hr style={{ marginTop: "-15px", marginBottom: "10px" }} />
                <Link to={"/ngo1"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    NGO
                  </Text>
                </Link>
                <Link to={"/section8"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Section 8
                  </Text>
                </Link>
                <Link to={"/trust_registration"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Trust Registration
                  </Text>
                </Link>
                <Link to={"/society_registration"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Society Registration
                  </Text>
                </Link>
              </Box>
              <Box
                display={hoverbox == 2 ? "block" : "none"}
                backgroundColor={"#FFFFFF"}
                borderRadius={"5px"}
                p={"10px 20px"}
              >
                <Text fontSize={"18px"} color={"black"}>
                 NGO Compliance
                </Text>
                <hr style={{ marginTop: "-15px", marginBottom: "10px" }} />
                <Link to={"/ngo_compliance"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    NGO Compliance
                  </Text>
                </Link>
                <Link to={"/section_8_compliance"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Section 8 Complaince
                  </Text>
                </Link>
                <Link to={"/csr_1_filing"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    CSR-1 Filing
                  </Text>
                </Link>
                <Link to={"/sec_80g_&_sec_12a"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    Sec.80G & Sec.12A
                  </Text>
                </Link>
              </Box>
            </Box>
          </Flex>
        
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export { NGO };


// {/* <Grid
// gridTemplateColumns={{ base: "repeat(3,33%)" }}
// width="90%"
// margin="auto"
// >
// <GridItem className="grid_item_in_hovered_div">
//   <Box className="hoverBoxHeadingText">Registration</Box>
//   <Box className="hoverBoxHeadingText_container">
//     <Link to={"/ngo1"}>
//       <Text
//         onClick={() => ChangeActive()}
//         className="hoverBoxHeadingText_container_child"
//       >
//         NGO
//       </Text>
//     </Link>
//     <Link to={"/section8"}>
//       <Text
//         onClick={() => ChangeActive()}
//         className="hoverBoxHeadingText_container_child"
//       >
//         Section 8
//       </Text>
//     </Link>
//     <Link to={"/trust_registration"}>
//       <Text
//         onClick={() => ChangeActive()}
//         className="hoverBoxHeadingText_container_child"
//       >
//         Trust Registration
//       </Text>
//     </Link>
//     <Link to={"/society_registration"}>
//       <Text
//         onClick={() => ChangeActive()}
//         className="hoverBoxHeadingText_container_child"
//       >
//         Society Registration
//       </Text>
//     </Link>
//   </Box>
// </GridItem>
// <GridItem className="grid_item_in_hovered_div">
//   <Box className="hoverBoxHeadingText">NGO Compliance</Box>
//   <Box className="hoverBoxHeadingText_container">
//     <Link to={"/ngo_compliance"}>
//       <Text
//         onClick={() => ChangeActive()}
//         className="hoverBoxHeadingText_container_child"
//       >
//         NGO Compliance
//       </Text>
//     </Link>
//     <Link to={"/section_8_compliance"}>
//       <Text
//         onClick={() => ChangeActive()}
//         className="hoverBoxHeadingText_container_child"
//       >
//         Section 8 Complaince
//       </Text>
//     </Link>
//     <Link to={"/csr_1_filing"}>
//       <Text
//         onClick={() => ChangeActive()}
//         className="hoverBoxHeadingText_container_child"
//       >
//         CSR-1 Filing
//       </Text>
//     </Link>
//     <Link to={"/sec_80g_&_sec_12a"}>
//       <Text
//         onClick={() => ChangeActive()}
//         className="hoverBoxHeadingText_container_child"
//       >
//         Sec.80G & Sec.12A
//       </Text>
//     </Link>
//     {/* <Link to={'/darpan_registration'}><Text onClick={() => ChangeActive()} className="hoverBoxHeadingText_container_child">Darpan Registration</Text></Link> */}
//   </Box>
// </GridItem>
// </Grid> */}
