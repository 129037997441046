import { Box, Card, Divider, Heading } from "@chakra-ui/react";

function History() {
  return (
    <Box bg="gray.100" m="4" p="4">
      <Card bg="white" p="8" boxShadow="lg" borderRadius="md">
        <Heading as="h2" mb="4" align="left" mt={4} pl={4} color="gray.600">
          History
        </Heading>
        <Divider mb={4} style={{ opacity: "0.2" }} />
        <Box>History</Box>
      </Card>
    </Box>
  );
}
export default History;
