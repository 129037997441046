import React from 'react'
import Top_gst_filinig from '../GST filing/component/Top_gst_filinig'
import Home6 from '../GST filing/component/Home6'
import Home5 from '../Home5/Home5'
import Navbar_AL from '../../Navbar_AL'
import Footer from '../../Footer'


const GST_filinig = () => {
    return (
        <div>
            <Navbar_AL/>
            <Top_gst_filinig />
            <Home5/>
            <Home6/>
            <Footer/>
        </div>
    )
}

export default GST_filinig

