import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Button,
  Divider,
  Flex,
  IconButton,
  Badge,
  Input,
  HStack,
  Select,
  FormControl,
  FormLabel,
  Image
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { RiFileUploadLine } from 'react-icons/ri';
import pako from 'pako';
import UserRegistration from './UserRegistration';
import { useDispatch, useSelector } from "react-redux";
import { handleGetVendor, handleUploadVendorDocument } from '../../../Redux/Profile/Profile.Action';

function DocumentVerification(){
  const [adharCardImage, setAdharCardImage] = useState(null);
  const [panCardImage, setPanCardImage] = useState(null);
  const [DrivingLisence, setDrivingLisence] = useState(null);
  const [BankPassbook, setBankPassbook] = useState(null);
  const [otherImage, setOtherImage] = useState(null);

  const dispatch = useDispatch();
  const { getVendorData } = useSelector((store) => store.Profile);

  useEffect(() => {
    dispatch(handleGetVendor);
  }, []);

  const handleSubmit = () => {
    const formData = new FormData();

    if (adharCardImage) {
      formData.append("adharCardImage", adharCardImage);
    }
    if (panCardImage) {
      formData.append("panCardImage", panCardImage);
    }
    if (DrivingLisence) {
      formData.append("DrivingLisence", DrivingLisence);
    }
    if (BankPassbook) {
      formData.append("BankPassbook", BankPassbook);
    }
    
    if (otherImage) {
      formData.append("otherImage", otherImage);
    }

    dispatch(handleUploadVendorDocument(formData));
  };

  const checkUrlType = (url) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif)$/i;
    const pdfExtension = /\.pdf$/i;

    if (imageExtensions.test(url)) {
      return (
        <img style={{ width: "100%", height: "100%" }} src={url} alt="Image" />
      );
    } else if (pdfExtension.test(url)) {
      return (
        <div>
          <iframe
            src={url}
            title="PDF Document"
            width="100%"
            height="100%"
          ></iframe>
          <a href={url} target="_blank" rel="noopener noreferrer">
            Open Pdf
          </a>
         
        </div>
      );
    } else {
      return (
        <div>
          <a href={url} target="_blank" rel="noopener noreferrer">
            View Document
          </a>
          <a href={url} download>
            Download Document
          </a>
        </div>
      );
    }
  };


  console.log("vendorData", getVendorData)

  return (
    <Box m="4" p="4">
      <Box bg="white" p="8" boxShadow="lg" borderRadius="md">
        <Heading as="h2" size="lg" mb="4" color="gray.600">
          Documents Upload and Verification
        </Heading>
        <Divider mb="4" />

        <Stack spacing="4">
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            alignItems="flex-start"
          >
            <Box bg="gray.100" p="4" borderRadius="md" flex="1" minH={"210px"}>
              <Text fontSize="lg" mb="4" color="orange">
                Upload Document
              </Text>
              <Box
                display={"grid"}
                gridTemplateColumns={"repeat(3, 1fr)"}
                alignItems={"center"}
                gap={"20px"}
              >
                <FormControl>
                  <FormLabel>Aadhar Card</FormLabel>
                  <Input
                    pt={"5px"}
                    type="file"
                    onChange={(e) => setAdharCardImage(e.target.files[0])}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Pan Card</FormLabel>
                  <Input
                    pt={"5px"}
                    type="file"
                    onChange={(e) => setPanCardImage(e.target.files[0])}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Driving Licence</FormLabel>
                  <Input
                    pt={"5px"}
                    type="file"
                    onChange={(e) => setDrivingLisence(e.target.files[0])}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Bank Passbook</FormLabel>
                  <Input
                    pt={"5px"}
                    type="file"
                    onChange={(e) => setBankPassbook(e.target.files[0])}
                  />
                </FormControl>

                {/* <FormControl>
                  <FormLabel>Company Logo</FormLabel>
                  <Input
                    pt={"5px"}
                    type="file"
                    onChange={(e) => setCompanyLogo(e.target.files[0])}
                  />
                </FormControl> */}

                <FormControl>
                  <FormLabel>Other Document</FormLabel>
                  <Input
                    pt={"5px"}
                    type="file"
                    onChange={(e) => setOtherImage(e.target.files[0])}
                  />
                </FormControl>
              </Box>
              <Box>
                <Button
                  w={"10%"}
                  mt={"30px"}
                  colorScheme="orange"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Flex>
        </Stack>

        {/* Uploaded Documents Section */}
        <Stack mt="30px">
        <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
          >
            <Heading as="h3" size="md">
              Uploaded Documents
            </Heading>
            {getVendorData?.accountStatus=="Approved" ? (
              <Box
                bg={"green.100"}
                color={"green.500"}
                fontWeight={"bold"}
                p={"5px 10px"}
                mb={"5px"}
              >
                {getVendorData?.accountStatus}
              </Box>
            ) : (
              <Box
                bg={"red.100"}
                color={"red.500"}
                fontWeight={"bold"}
                p={"5px 10px"}
                mb={"5px"}
              >
                {getVendorData?.accountStatus}
              </Box>
            )}
          </Box>
          
          <Box
            mt={"20px"}
            display={"flex"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            gap={"20px"}
          >
            {getVendorData?.adharCardImage && (
              <Box width={"10%"}>
                {/* <Image
                  w={"100%"}
                  height={"100%"}
                  src={getVendorData?.adharCardImage}
                  alt="image"
                /> */}
                {checkUrlType(getVendorData?.adharCardImage)}
                <Text>Aadhar Card</Text>
              </Box>
            )}

            {getVendorData?.panCardImage && (
              <Box width={"10%"}>
                {/* <Image
                  w={"100%"}
                  height={"100%"}
                  src={getVendorData?.panCardImage}
                  alt="image"
                /> */}
                {checkUrlType(getVendorData?.panCardImage)}
                <Text>Pan Card</Text>
              </Box>
            )}

            {getVendorData?.DrivingLisence && (
              <Box width={"10%"}>
                {/* <Image
                  w={"100%"}
                  height={"100%"}
                  src={getVendorData?.DrivingLisence}
                  alt="image"
                /> */}
                {checkUrlType(getVendorData?.DrivingLisence)}
                <Text>Driving Lisence</Text>
              </Box>
            )}

            {getVendorData?.bankPassbook && (
              <Box width={"10%"}>
                {/* <Image
                  w={"100%"}
                  height={"100%"}
                  src={getVendorData?.bankPassbook}
                  alt="image"
                /> */}
                {checkUrlType(getVendorData?.bankPassbook)}
                <Text>Bank Passbook</Text>
              </Box>
            )}

            {/* {getVendorData?.companyLogo && (
              <Box width={"10%"}>
                <Image
                  w={"100%"}
                  height={"100%"}
                  src={getVendorData?.companyLogo}
                  alt="image"
                />
                <Text>Company Logo</Text>
              </Box>
            )} */}

            {getVendorData?.otherImage && (
              <Box width={"10%"}>
                {/* <Image
                  w={"100%"}
                  height={"100%"}
                  src={getVendorData?.otherImage}
                  alt="image"
                /> */}
                {checkUrlType(getVendorData?.otherImage)}
                <Text>Other Document</Text>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default DocumentVerification