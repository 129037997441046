import { React, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  Select,
  Text,
  VStack,
  Wrap,
  Heading,
  List,
  ListIcon,
  OrderedList,
  UnorderedList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AspectRatio,
  useDisclosure,
  FormLabel,
  FormControl,
  Stack,
  Checkbox,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import b2b2 from "../assets/b2b2.svg";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { handleVendorRegister } from "../../Redux/Auth/Auth.Action";
import { Inputvalidate } from "../helpers/inputValidate";
import { hasValidationError, validationError } from "../helpers/Frontend";

const VendorSignup = () => {
  const [form, setForm] = useState({
    VendorName: "",
    email: "",
    mobileNo: "",
    address: "",
    state: "",
    city: "",
    aadharNumber: "",
    panNo: "",
    designation: "",
    adharCardImage: null,
    panCardImage: null,
    otherImage: null,
    image: null,
  });

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setForm({
      ...form,
      [name]: files[0],
    });
  };

  const handleRegister = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("VendorName", form.VendorName);
    formData.append("email", form.email);
    formData.append("mobileNo", form.mobileNo);
    formData.append("address", form.address);
    formData.append("state", form.state);
    formData.append("city", form.city);
    formData.append("aadharNumber", form.aadharNumber);
    formData.append("panNo", form.panNo);
    formData.append("designation", form.designation);
    formData.append("adharCardImage", form.adharCardImage);
    formData.append("panCardImage", form.panCardImage);
    formData.append("otherImage", form.otherImage);
    formData.append("image", form.image);

    dispatch(handleVendorRegister(formData, navigate));

    setForm({
      VendorName: "",
      email: "",
      mobileNo: "",
      address: "",
      state: "",
      city: "",
      aadharNumber: "",
      panNo: "",
      designation: "",
      adharCardImage: null,
      panCardImage: null,
      otherImage: null,
      image: null,
    });
  };

  return (
    <HStack
      bgImage={""}
      style={{
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        // backgroundPosition: "center",
        // boxSizing: "border-box",
      }}
      flexDirection={{
        base: "column",
        md: "row",
        lg: "row",
      }}
      bgColor={"#FFB91D"}
      p={"30px 0px"}
    >
      <Box
        bgColor={"#FFB91D"}
        width={{ base: "100%", md: "50%", lg: "50%" }}
        color={"white"}
        padding={"30px"}
      >
        <Flex
          style={{ justifyContent: "space-evenly" }}
          flexDirection={"column"}
          gap={"100px"}
        >
          <VStack>
            <h2>1. Expert consultation</h2>
            <Text color={"whiteAlpha.900"}>
              {" "}
              Our experts can identify potential threats & mitigate strategies
              for your business
            </Text>
          </VStack>
          <VStack>
            <h2>2. Timely reminders</h2>
            <Text color={"whiteAlpha.900"}>
              {" "}
              Increase efficiency & mange time by staying updated on all
              compliances
            </Text>
          </VStack>
          <VStack>
            <h2>3. One location access</h2>
            <Text color={"whiteAlpha.900"}>
              {" "}
              Manage, monitor, and analyze all of your businesses from a single
              accessible dashboard
            </Text>
          </VStack>
        </Flex>
      </Box>
      <VStack
        bgImage={b2b2}
        style={{
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
          // backgroundPosition: "center",
          boxSizing: "border-box",
          margin: "auto",
        }}
        className="container"
        width={{ base: "80%", md: "50%", lg: "50%" }}
        padding={"10px"}
        color={"white"}
      >
        
        <Box
          className="container"
          rounded={"lg"}
          // bg={useColorModeValue('rbg(200,200,200)',)}
          color={"black"}
          backgroundColor={"white"}
          boxShadow={"lg"}
          p={8}
          width={{ base: "100%", md: "80%", lg: "80%" }}
        >
          <Heading color={"black"} fontFamily={"serif"}>Vendor Sign Up</Heading>
          <Box display={"grid"} gridTemplateColumns={"repeat(2, 1fr)"} alignItems={"center"} gap={"10px"}>
            <FormControl id="fullname">
              <FormLabel>Vendor Name</FormLabel>
              <Input
                type="text"
                name="VendorName"
                value={form.VendorName}
                onChange={handleChange}
                isRequired
              />
            </FormControl>

            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                type="text"
                name="email"
                value={form.email}
                onChange={handleChange}
                isRequired
              />
            </FormControl>

            <FormControl>
              <FormLabel>Mobile Number</FormLabel>
              <Input
                type="tel"
                name="mobileNo"
                value={form.mobileNo}
                onChange={handleChange}
                maxLength={10}
                isRequired
              />
            </FormControl>
            <FormControl id="phoneno">
              <FormLabel>Address</FormLabel>
              <Input
                type="text"
                name="address"
                value={form.address}
                onChange={handleChange}
                isRequired
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>City</FormLabel>
              <Input
                type="text"
                name="city"
                value={form.city}
                onChange={handleChange}
                isRequired
              />
            </FormControl>

            <FormControl id="password">
              <FormLabel>State</FormLabel>
              <Input
                type="text"
                name="state"
                value={form.state}
                onChange={handleChange}
                isRequired
              />
            </FormControl>

            <FormControl id="password">
              <FormLabel>Aadhar Number</FormLabel>
              <Input
                type="text"
                name="aadharNumber"
                value={form.aadharNumber}
                onChange={handleChange}
                isRequired
              />
            </FormControl>

            <FormControl id="password">
              <FormLabel>Pan Number</FormLabel>
              <Input
                type="text"
                name="panNo"
                value={form.panNo}
                onChange={handleChange}
                isRequired
              />
            </FormControl>

            <FormControl id="password">
              <FormLabel>Designation</FormLabel>
              <Input
                type="text"
                name="designation"
                value={form.designation}
                onChange={handleChange}
                isRequired
              />
            </FormControl>

            <FormControl id="password">
              <FormLabel>Aadhar Card</FormLabel>
              <Input
                pt={"4px"}
                type="file"
                // accept="image/*"
                name="adharCardImage"
                onChange={handleImageChange}
                isRequired
              />
            </FormControl>

            <FormControl id="password">
              <FormLabel>Pan Card</FormLabel>
              <Input
                pt={"4px"}
                type="file"
                // accept="image/*"
                name="panCardImage"
                onChange={handleImageChange}
                isRequired
              />
            </FormControl>

            <FormControl id="password">
              <FormLabel>Other Document</FormLabel>
              <Input
                pt={"4px"}
                type="file"
                // accept="image/*"
                name="otherImage"
                onChange={handleImageChange}
                isRequired
              />
            </FormControl>

            <FormControl id="password">
              <FormLabel>Vendor Image</FormLabel>
              <Input
                pt={"4px"}
                type="file"
                accept="image/*"
                name="image"
                onChange={handleImageChange}
                isRequired
              />
            </FormControl>

            
          </Box>
          <Stack spacing={10} mt={"20px"}>
              <Button
                bg={"#FFB91D"}
                color={"white"}
                _hover={{
                  bg: "orange",
                }}
                onClick={handleRegister}
              >
                Sign Up
              </Button>
            </Stack>
          <Box mt={"40px"}>
            <Text>
              Already have account? <Link to={"/vendor-login"}>Sign In</Link>
            </Text>
          </Box>
        </Box>
      </VStack>
    </HStack>
  );
};

export default VendorSignup;
