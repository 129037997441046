import React, { useEffect, useState } from 'react'
import Slidebar from '../Slidebar/Slidebar'
import { Box, Button, Checkbox, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import Company_name from '../Company_name/Company_name'
import Sold_ShipTo from './Sold_ShipTo';
import Invoice from './Invoice'
import TableOptions from './TableOptions'
import { FaCalculator, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { postSaleInvoice } from "../../../../Redux/SaleInvoice/saleInvoice.Action.js";
import { useParams } from 'react-router-dom';
import Calculator from './Calculator.jsx';
import axios from 'axios';


const Company = {
    name: "Company Name"
}

const BillingSoftware = () => {


































    const [availableItems, setAvailableItems] = useState([]);
    const modal2 = useDisclosure();
    const openAddItemModal = () => {
        modal2.onOpen();
    };
    const stockData = useSelector((store) => store.stockReducer.getStockData);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemsData, setSelectedItemsData] = useState([]);

    const handleCheckboxChange = (itemId) => {
        // Toggle the selected state of the item
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                return prevSelectedItems.filter((id) => id !== itemId);
            } else {
                return [...prevSelectedItems, itemId];
            }
        });

        // Set the details of the selected items
        const selectedItem = stockData.find((item) => item._id == itemId);
        setSelectedItemsData((prevSelectedItemsData) => {
            const updatedSelectedItemsData = [...prevSelectedItemsData];
            const existingItemIndex = updatedSelectedItemsData.findIndex(
                (item) => item._id == itemId
            );

            if (existingItemIndex != -1) {
                // If item data exists, update it
                updatedSelectedItemsData[existingItemIndex] = selectedItem;
            } else {
                // If item data doesn't exist, add it
                updatedSelectedItemsData.push(selectedItem);
            }

            return updatedSelectedItemsData;
        });
    };


    const modal1 = useDisclosure();
    // const { firmId } = useSelector((store) => store.FirmRegistration);
    const { invoiceId } = useParams();
    const dispatch = useDispatch();
    const userDetails = JSON.parse(sessionStorage.getItem("companyDetails")) || {};
    const { token } = userDetails;
    const { getOneInvoice } = useSelector((store) => store.invoiceReducer);
    const partyId = sessionStorage.getItem("selectedPartyId");

    const handlePrint = () => {
        window.print();
    }

    const [calculatorResult, setCalculatorResult] = useState('');
    const [isCalculatorOpen, setCalculatorOpen] = useState(false);

    const handleToggleCalculator = () => {
        setCalculatorOpen((prev) => !prev);
    };

    const handleCalculatorResult = (result) => {
        setCalculatorResult(result);
    };

    const [formData, setFormData] = useState({
        soldToCustomerName: '',
        shipToCustomerName: '',
        soldToCustomerAddress: '',
        shipToCustomerAddress: '',
        customerEmail: '',
        subTotal: '',
        discount: '',
        finalAmount: '',
        invoiceNo: '',
        invoiceDate: '',
        paymentMode: '',
        gstNo: '',
        dueDate: '',
        items: [],
        paidAmount: '',
        dueAmount: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name == 'paidAmount' || name == 'dueAmount' || name == 'discount') {

            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                firmId: firmId,
                subTotal: subTotal.toFixed(2),
                discount: discount.toFixed(2),
                finalAmount: finalAmount.toFixed(2),
            }));

            const paidAmount = parseFloat(formData.paidAmount) || 0;
            const dueAmount = parseFloat(formData.dueAmount) || 0;
            const discount = parseFloat(value) || 0;  // Use the selected value directly
            const subTotal = paidAmount + dueAmount;

            const finalAmount = subTotal - subTotal * (value / 100);

        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                firmId: firmId,
            }));
        }
    };

    const submitInvoice = () => {
        dispatch(postSaleInvoice(formData, token, firmId));
    }

    // ✔️✔️✔️✔️✔️✔️ ITEM TABLE 
    const [rows, setRows] = useState([{
        itemId: "",
        itemName: "",
        category: "",
        quantity: "",
        unit: "",
        discount: "",
        itemPrice: "",
        gstRate: "",
        gstAmount: "",
        totalPrice: "",
    }]);

    const [item, setItem] = useState(null)
    const { firmId } = useSelector((store) => store.FirmRegistration);


    // const calculateTotalPrices = (openingQuantity, salesPric, gstAmount) => {
    //     const parsedQuantity = parseFloat(openingQuantity);
    //     const parsedItemPrice = parseFloat(salesPric);
    //     const parsedGST = parseFloat(gstAmount);

    //     if (!isNaN(parsedQuantity) && !isNaN(parsedItemPrice)) {
    //         // Calculate total price and add GST if applicable
    //         const totalWithoutGST = parsedQuantity * parsedItemPrice;
    //         const totalWithGST = parsedGST
    //             ? totalWithoutGST + (totalWithoutGST * (parsedGST / 100))
    //             : totalWithoutGST;

    //         // Return the total price as a number
    //         return totalWithGST;
    //     }

    //     return 0; // Return 0 if any of the input values is not a number
    // };

    const handleInputChangeFromItemsTables = (e, field, rowId) => {
        const { value } = e.target;
        const updatedRows = rows.map((row) => {

            if (row.id === rowId) {

                if (field === 'quantity') {
                    row.quantity = value
                }

                if (field === 'itemPrice') {
                    row.itemPrice = value
                }

                if (field === 'gst') {
                    row.gst = value
                }

                const parsedQuantity = parseFloat(row.quantity);
                const parsedItemPrice = parseFloat(row.itemPrice);
                const gstInputValue = row.gst;
                const parsedGST = parseFloat(gstInputValue?.match(/\d+/)[0]);
                console.log("parseGsTttttttttttt", parsedGST)
                const totalPrice = calculateTotalPrice(parsedQuantity, parsedItemPrice, parsedGST);
                let gstAmount = (row.quantity * row.itemPrice) * (parsedGST / 100)
                return {
                    ...row,
                    [field]: value,
                    gstAmount: gstAmount,
                    totalPrice: totalPrice.toFixed(2), 
                };
            }
            return row;
        });

        setRows(updatedRows);
    };


    const addRow = () => {
        const newItem = { id: rows.length + 1, itemName: '', category: '', quantity: '', unit: '', discount: '', itemPrice: '', GST: '', totalPrice: '', };
        setRows([...rows, newItem]);
    }

    const deleteRow = (rowId) => {
        const updatedRows = rows.filter((row) => row.id !== rowId);
        setRows(updatedRows);
    };

    // ✅✅✅✅✅

    let companyDetails = JSON.parse(sessionStorage.getItem('companyDetails'))
    let headers = {
        "token": companyDetails.token
    }

    useEffect(() => {
        console.log("inside......................dddd")
        axios.get(`https://ca-api-9yu6.onrender.com/item/${firmId}/products`, { headers })
            .then((res) => {
                setAvailableItems(res.data);
                console.log("ressssssssssssssssssssssssssssssssssss", res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    // ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
    // Add this function to your component
    const handleItemClick = (selectedItem, rowId) => {
        const updatedRows = rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    itemName: selectedItem.itemName,
                    category: selectedItem.category,
                };
            }
            return row;
        });

        setRows(updatedRows);
        setItem('');
    };


    useEffect(() => {
        if (getOneInvoice) {
            setFormData({
                soldToCustomerName: getOneInvoice.soldToCustomerName || '',
                shipToCustomerName: getOneInvoice.shipToCustomerName || '',
                soldToCustomerAddress: getOneInvoice.soldToCustomerAddress || '',
                shipToCustomerAddress: getOneInvoice.shipToCustomerAddress || '',
                customerEmail: getOneInvoice.customerEmail || '',
                subTotal: getOneInvoice.subTotal || '',
                discount: getOneInvoice.discount || '',
                finalAmount: getOneInvoice.finalAmount || '',
                invoiceNo: getOneInvoice.invoiceNo || '',
                invoiceDate: getOneInvoice.invoiceDate || '',
                paymentMode: getOneInvoice.paymentMode || '',
                gstNo: getOneInvoice.gstNo || '',
                dueDate: getOneInvoice.dueDate || '',
                items: getOneInvoice.items || [],
                paidAmount: getOneInvoice.paidAmount || '',
                dueAmount: getOneInvoice.dueAmount || ''
            });
        }
    }, [getOneInvoice]);

    console.log("availableeeeeeeeeeeeeeeeeee", availableItems)






















   



    // const handleInputChangeFromItemsTable = (e, field, id) => {
    //     const updatedRows = rows.map((currentRow) => {
    //         if (currentRow.id === id) {
    //             if (field === 'itemName') {
    //                 const selectedItem = availableItemss.find((item) => item.itemName === e.target.value);

    //                 if (selectedItem) {
    //                     console.log('Selected Item:', selectedItem);
    //                     return {
    //                         ...currentRow,
    //                         category: selectedItem.category,
    //                         openingQuantity: selectedItem.openingQuantity,
    //                         unit: selectedItem.unit,
    //                         discount: selectedItem.discount,
    //                         salesPric: selectedItem.salesPric,
    //                         gstRate: selectedItem.gstRate,
    //                         gstAmount: selectedItem.gstAmount,
    //                         totalPrice: selectedItem.totalPrice,
    //                     };
    //                 } else {
    //                     console.log('Selected Item not found in availableItemss');
    //                 }
    //             } else {
    //                 return { ...currentRow, [field]: e.target.value };
    //             }
    //         }
    //         return currentRow;
    //     });

    //     console.log('Updated Rows:', updatedRows); 

    //     setRows(updatedRows);
    // };




    // const handleInputChangeFromItemsTable = (e, field, id) => {
    //     const updatedRows = rows.map((currentRow) => {
    //       if (currentRow.id === id) {
    //         if (field === 'itemName') {
    //           const selectedItem = availableItemss.find((item) => item.itemName === e.target.value);
      
    //           if (selectedItem) {
    //             console.log('Selected Item:', selectedItem);
               
    //             const updatedRow = {
    //               ...currentRow,
    //               category: selectedItem.category,
    //               openingQuantity: selectedItem.openingQuantity,
    //               unit: selectedItem.unit,
    //               discount: selectedItem.discount,
    //               salesPric: selectedItem.salesPric,
    //               gstRate: selectedItem.gstRate,
    //               gstAmount: selectedItem.gstAmount,
    //               totalPrice: calculateTotalPrice(selectedItem.openingQuantity, selectedItem.salesPric, selectedItem.gstAmount),
    //             };
      
    //             return updatedRow;
    //           } else {
    //             console.log('Selected Item not found in availableItemss');
    //           }
    //         } else {
             
    //           return { ...currentRow, [field]: e.target.value };
    //         }
    //       }
    //       return currentRow;
    //     });
      
    //     console.log('Updated Rows:', updatedRows);
      
    //     setRows(updatedRows);
    //   };


    const [availableItemss, setAvailableItemss] = useState([]);
    const [selectedItemName, setSelectedItemName] = useState(null);


    useEffect(() => {
        axios.get(`https://ca-api-9yu6.onrender.com/item/${firmId}/products`, { headers })
            .then((res) => {
                setAvailableItemss(res.data);
                console.log("Available Items:", res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [firmId]);


    const calculateTotalPrice = (openingQuantity, salesPrice, discount, gstRate, gstAmount) => {
        const parsedQuantity = parseFloat(openingQuantity);
        const parsedItemPrice = parseFloat(salesPrice);
        const parsedDiscount = parseFloat(discount);
        const parsedGSTRate = parseFloat(gstRate);
        const parsedGSTAmount = parseFloat(gstAmount);
      
        if (!isNaN(parsedQuantity) && !isNaN(parsedItemPrice)) {
          const totalWithoutDiscount = parsedQuantity * parsedItemPrice;
          const discountAmount = (parsedDiscount / 100) * totalWithoutDiscount;
          const totalWithDiscount = totalWithoutDiscount - discountAmount;
          const totalWithGST = parsedGSTRate ? totalWithDiscount + parsedGSTAmount : totalWithDiscount;
      
          return totalWithGST;
        }
      
        return 0;
      };
      

      

      const calculateGSTAmount = (salesPrice, gstRate, openingQuantity) => {
        const parsedSalesPrice = parseFloat(salesPrice);
        const parsedGSTRate = parseFloat(gstRate);
        const parsedQuantity = parseFloat(openingQuantity);
    
        if (!isNaN(parsedSalesPrice) && !isNaN(parsedGSTRate) && !isNaN(parsedQuantity)) {
          const totalWithoutGST = parsedSalesPrice * parsedQuantity;
          const gstAmount = totalWithoutGST * (parsedGSTRate / 100);
    
          return gstAmount;
        }
    
        return 0;
      };
      

    

// good one
const handleInputChangeFromItemsTable = (e, field, id) => {
    const updatedRows = rows.map((currentRow) => {
      if (currentRow.id === id) {
        if (field === 'itemName') {
          const selectedItem = availableItemss.find((item) => item.itemName === e.target.value);

          if (selectedItem) {
            console.log('Selected Item:', selectedItem);

            const updatedRow = {
              ...currentRow,
              category: selectedItem.category,
              openingQuantity: selectedItem.openingQuantity,
              unit: selectedItem.unit,
              discOnMrpforsale: selectedItem.discOnMrpforsale,
              salesPric: selectedItem.salesPric,
              gstRate: selectedItem.gstRate,
              gstAmount: selectedItem.gstAmount,
              itemPrice: parseFloat(selectedItem.salesPric),
              totalPrice: calculateTotalPrice(selectedItem.openingQuantity, selectedItem.salesPric, selectedItem.discOnMrpforsale, selectedItem.gstAmount),
            };

            return updatedRow;
          } else {
            console.log('Selected Item not found in availableItemss');
          }
        } else {
            const updatedRow = {
                ...currentRow,
                [field]: e.target.value,
                totalPrice: calculateTotalPrice(
                  field === 'openingQuantity' ? e.target.value : currentRow.openingQuantity,
                  field === 'salesPric' ? e.target.value : currentRow.salesPric,
                  field === 'discOnMrpforsale' ? e.target.value : currentRow.discOnMrpforsale,
                  field === 'gstRate' ? e.target.value : currentRow.gstRate,
                  calculateGSTAmount(
                    field === 'salesPric' ? e.target.value : currentRow.salesPric,
                    field === 'gstRate' ? e.target.value : currentRow.gstRate,
                    field === 'openingQuantity' ? e.target.value : currentRow.openingQuantity
                  )
                ),
                gstAmount: calculateGSTAmount(
                  field === 'salesPric' ? e.target.value : currentRow.salesPric,
                  field === 'gstRate' ? e.target.value : currentRow.gstRate,
                  field === 'openingQuantity' ? e.target.value : currentRow.openingQuantity
                ),
              };
    

          return updatedRow;
        }
      }
      return currentRow;
    });

    setRows(updatedRows);
  };
    //   good one


    
      







    return (
        <>
            <Company_name company_name={Company.name} />
            <Flex>
                <Slidebar />
                <Box flex='1' flexDirection='column' p='1'>
                    {/* basic details and invoice */}
                    <Flex justifyContent='space-between'>
                        <Flex width="60%"
                            flexDirection="column"
                            p='2'
                            margin='15px'
                            border='0.1px solid lightgray'
                            boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px'
                        >
                            <Sold_ShipTo formData={formData} setFormData={setFormData} />
                            <Flex direction="column" mt='2' p='2'>
                                <Flex justifyContent='space-between' p='2'>
                                    <Flex align='left' mr='4'>

                                        <Text textAlign='left' fontWeight='semibold' mr='2'>Customer Email: </Text>
                                        <Input name="customerEmail" size='sm' width='250px' placeholder='Enter customer email' value={formData?.customerEmail} onChange={handleInputChange} />
                                    </Flex>

                                    <Button
                                        colorScheme="gray"
                                        leftIcon={<FaCalculator />}
                                        variant="outline"
                                        size="sm"
                                        onClick={handleToggleCalculator}
                                    >
                                        <Flex alignItems="center">
                                            <Text ml="2" fontSize="sm" mt='4'>
                                                Calculator
                                            </Text>
                                        </Flex>
                                    </Button>
                                    {isCalculatorOpen && <Calculator onCalculate={handleCalculatorResult} />}
                                </Flex>

                                {/* ... (rest of the code) */}
                                <TableOptions />
                            </Flex>
                        </Flex>
                        {/* Invoice */}
                        <Box width="40%"
                            p='2'
                            margin='15px'
                            border='0.1px solid lightgray'
                            boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px'
                        >
                            <Invoice formData={formData} setFormData={setFormData} />
                        </Box>
                    </Flex>
                    {/* table */}
                    {/* <ItemsTable /> */}
                    {/* ✅✅✅✅✅✅✅ ITEM TABLE ✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ */}
                    <TableContainer
                        bg="white"
                        margin="15px"
                        border="0.1px solid lightgray"
                        boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
                    >
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    {/* <Th style={{ border: '1px solid lightgray' }}>Item ID</Th> */}
                                    <Th style={{ border: '1px solid lightgray' }}>Item Name</Th>
                                    <Th style={{ border: '1px solid lightgray' }}>Category</Th>
                                    <Th style={{ border: '1px solid lightgray' }}>Quantity</Th>
                                    <Th style={{ border: '1px solid lightgray' }}>Unit</Th>
                                    <Th style={{ border: '1px solid lightgray' }}>Discount</Th>
                                    <Th style={{ border: '1px solid lightgray' }}>Item Price</Th>
                                    <Th style={{ border: '1px solid lightgray' }}>GST % </Th>
                                    <Th style={{ border: '1px solid lightgray' }}>GST Amount  </Th>
                                    <Th style={{ border: '1px solid lightgray' }}>Total Price</Th>
                                </Tr>
                            </Thead>

                            <Tbody>

                                {rows.map((row) => (
                                    <Tr key={row.itemId} >
                                        <Td style={{ border: '1px solid gray' }}>
                                            {/* <Select
        variant='unstyled'
        placeholder='Item Name'
        value={selectedItemName}
        onChange={(e) => handleInputChangeFromItemsTable(e, 'itemName', row.id)}
        required
      >
        <option value="" disabled>Select an item</option>
        {availableItemss.map((item) => (
          <option key={item.itemId} value={item.itemName}>
            {item.itemName}
          </option>
        ))}
      </Select> */}

                                            <Select
                                                variant='unstyled'
                                                placeholder='Item Name'
                                                value={selectedItemName}  // Change this line
                                                onChange={(e) => {
                                                    setSelectedItemName(e.target.value);
                                                    handleInputChangeFromItemsTable(e, 'itemName', row.id);
                                                }}
                                                required
                                            >
                                                <option value="" disabled>Select an item</option>
                                                {availableItemss.map((item) => (
                                                    <option key={item.itemId} value={item.itemName}>
                                                        {item.itemName}
                                                    </option>
                                                ))}
                                            </Select>

                                        </Td>

                                        <Td style={{ border: '1px solid gray' }}>
                                            <Input
                                                variant='unstyled'
                                                placeholder='Category'
                                                value={row.category}
                                                onChange={(e) =>
                                                    handleInputChangeFromItemsTable(e, 'category', row.id)
                                                }
                                            />
                                        </Td>
                                        <Td style={{ border: '1px solid gray' }}>
                                            <Input
                                                variant='unstyled'
                                                placeholder='Quantity'
                                                value={row.openingQuantity}
                                                onChange={(e) =>
                                                    handleInputChangeFromItemsTable(e, 'openingQuantity', row.id)
                                                }
                                            />
                                        </Td>


                                        


                                        <Td width='120px' padding='0px 0px' style={{ border: '1px solid gray' }}>
                                            {/* <Input
                                                variant='unstyled'
                                                placeholder='unit'
                                                value={row.unit}
                                                onChange={(e) =>
                                                    handleInputChangeFromItemsTable(e, 'unit', row.id)
                                                }
                                            /> */}

                                            <Select fontSize='15px' value={row.unit} variant='unstyled' placeholder='Unit'>
                                                <option value="">None</option>
                                                <option value="bags">BAGS(Bag)</option>
                                                <option value="bottle">BOTTLE(Btl)</option>
                                                <option value="box">BOX(Box)</option>
                                                <option value="bundles">BUNDLES(Bdl)</option>
                                                <option value="cans">CANS(can)</option>
                                                <option value="cartons">CARTONS(Ctn)</option>
                                                <option value="dogens">DOGENS(Dzn)</option>
                                                <option value="grammes">GRAMMES(GM)</option>
                                                <option value="kilograms">KILOGRAMS(KG)</option>
                                                <option value="litters">LITTERS(Ltr)</option>
                                                <option value="meaters">MEATERS(Mtr)</option>
                                                <option value="mililiter">MILILITER(Ml)</option>
                                                <option value="numbers">NUMBERS(Nos)</option>
                                                <option value="packs">PACKS(Pac)</option>
                                                <option value="pairs">PAIRS(Prs)</option>
                                                <option value="pieces">PIECES(Pcs)</option>
                                                <option value="quintals">QUINTALS(Qtl)</option>
                                                <option value="rolls">ROLLS(Rol)</option>
                                                <option value="squarefeet">SQUARE FEET(Sqf)</option>

                                            </Select>
                                        </Td>
                                        <Td style={{ border: '1px solid gray' }}>
                                            <Input
                                                variant='unstyled'
                                                placeholder='discount'
                                                value={row.discOnMrpforsale}
                                                onChange={(e) =>
                                                    handleInputChangeFromItemsTable(e, 'discOnMrpforsale', row.id)
                                                }
                                            />
                                        </Td>
                                        <Td style={{ border: '1px solid gray' }}>
                                            <Input
                                                variant='unstyled'
                                                placeholder='item price'
                                                value={row.salesPric}
                                                onChange={(e) =>
                                                    handleInputChangeFromItemsTable(e, 'salesPric', row.id)

                                                }
                                            />
                                        </Td>
                                        <Td padding='0px 0px' width='130px' style={{ border: '1px solid gray' }}>
                                            {/* <Input
                                                variant='unstyled'
                                                placeholder='GST@0'
                                                value={row.gst}
                                                onChange={(e) =>
                                                    handleInputChangeFromItemsTable(e, 'gst', row.id)
                                                }
                                            /> */}
                                           <Select
  onChange={(e) =>
    handleInputChangeFromItemsTable(e, 'gstRate', row.id)
  }
  value={row.gstRate}
  variant='unstyled'
  placeholder='GST'
>
  <option value='None'>None</option>
  <option value='0'>GST@0</option>
  <option value='0'>IGST@0</option>
  <option value='0.25'>IGST@0.25%</option>
  <option value='0.25'>GST@0.25%</option>
  <option value='3'>IGST@3%</option>
  <option value='3'>GST@3%</option>
  <option value='5'>IGST@5%</option>
  <option value='5'>GST@5%</option>
  <option value='12'>IGST@12%</option>
  <option value='12'>GST@12%</option>
  <option value='18'>IGST@18%</option>
  <option value='18'>GST@18%</option>
  <option value='28'>IGST@28%</option>
  <option value='28'>GST@28%</option>
  <option value='exempt'>exempt</option>
</Select>

                                        </Td>

                                        <Td style={{ border: '1px solid gray' }}>
                                            <Input
                                                variant='unstyled'
                                                placeholder='gstAmount'
                                                value={row.gstAmount}
                                                onChange={(e) =>
                                                    handleInputChangeFromItemsTable(e, 'gstAmount', row.id)
                                                }
                                            />
                                        </Td>
                                        <Td style={{ border: '1px solid gray' }}>
                                            <Input
                                                variant='unstyled'
                                                placeholder='total price'
                                                value={row.totalPrice}
                                                onChange={(e) =>
                                                    handleInputChangeFromItemsTable(e, 'totalPrice', row.id)
                                                }
                                            />
                                        </Td>
                                        <Td style={{ border: '1px solid gray' }}>
                                            <FaTrash onClick={() => deleteRow(row.id)} />
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                            <Box marginLeft='0px'>
                                <Button onClick={addRow} color='green.400' sx={{ marginLeft: '0px', padding: '15px ', fontSize: '14px', border: '1px solid black', }} >+ ADD NEW ITEM</Button>
                            </Box>
                        </Table>
                    </TableContainer>
                    {/* ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️ */}
                    {/* below table */}
                    <Flex>
                        <Flex p='4'
                            height='90%'
                            direction='column'
                            justifyContent='left'
                            margin='15px'
                            width='60%'
                            marginRight='auto'
                            border='0.1px solid lightgray'
                            boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px'
                        >
                            <Flex justifyContent='space-around'>
                                <Flex>
                                    <Text fontSize='md' fontWeight='semibold' mr='2'>Paid Amount : </Text>
                                    <Input flex="1" ml="2" size="sm" name="paidAmount" onChange={handleInputChange} />
                                </Flex>
                                <Flex>
                                    <Text fontSize='md' fontWeight='semibold' mr='2'>Due Amount : </Text>
                                    <Input flex="1" ml="2" size="sm" name="dueAmount" onChange={handleInputChange} />
                                </Flex>
                            </Flex>
                            <Flex justifyContent='space-between' alignItems='flex-end'>
                                <Button onClick={modal1.onClose} width='30%' outline='none'>Close</Button>
                                <Button width='30%' outline='none' onClick={submitInvoice}>Save</Button>
                                <Button onClick={handlePrint} width='30%' outline='none'>Print</Button>
                            </Flex>
                        </Flex>
                        <Flex pt='4' px='2'
                            justifyContent='right'
                            margin='15px'
                            width='32%'
                            marginLeft='auto'
                            border='0.1px solid lightgray'
                            boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px'
                        >
                            <Flex direction='column'>
                                <Flex mb="2">
                                    <Text flex="0 0 120px"
                                        alignItem='left'
                                    >Sub Total : </Text>
                                    <Input flex="1" ml="2" size="sm" readOnly value={formData?.subTotal} />
                                </Flex>
                                <Flex mb="2">
                                    {/* <Text flex="0 0 120px">Discount : </Text> */}
                                    {/* <Select
                                        name="discount"
                                        size="sm"
                                        width="120px"
                                        value={formData?.discount}
                                        onChange={(e) => handleInputChange(e)}
                                    >
                                        <option value="0%">0%</option>
                                        <option value="1%">1%</option>
                                        <option value="3%">3%</option>
                                        <option value="5%">5%</option>
                                        <option value="10%">10%</option>
                                    </Select> */}

                                </Flex>
                                {/* <Flex mb="2">
                                    <Text flex="0 0 120px">Final Amount : </Text>
                                    <Input flex="1" ml="2" size="sm" readOnly value={formData?.finalAmount} />
                                </Flex> */}
                            </Flex>
                        </Flex>
                    </Flex>
                </Box>
            </Flex>
        </>
    )
}

export default BillingSoftware;