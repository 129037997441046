import React from "react";
import { Line } from "react-chartjs-2";
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Card,
  CardHeader,
  Divider,
} from "@chakra-ui/react";

function WorkingPerformance() {
  const target = 100;
  const achievement = 85;
  const incentive = 5000;
  const performanceData = {
    labels: ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ],
    datasets: [
      {
        label: "Sales Performance",
        data: [65, 59, 80, 81, 56, 55],
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };
  const holidays = ["New Year", "Christmas", "Thanksgiving", "New Year", "Christmas", "Thanksgiving"];

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Box bg="gray.100" p="4" m="4">
      <Card bg="white" boxShadow="lg" borderRadius="md" p="8">
        <Heading as="h1" mb="4" align="left" mt={4} pl={4} color="gray.600">
          Vendor working porformance
        </Heading>
        <Divider mb={4} style={{ opacity: "0.2" }} />
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <Box mb={4} bg="gray.100" boxShadow="md" borderRadius="md" p="4">
              <Heading as="h3" size="md" mb={2}>
                Target
              </Heading>
              <Text>Target: {target}</Text>
            </Box>

            <Box mb={4} bg="gray.100" boxShadow="md" borderRadius="md" p="4">
              <Heading as="h3" size="md" mb={2}>
                Achievement
              </Heading>
              <Text>Achievement: {achievement}</Text>
            </Box>

            <Box mb={4} bg="gray.100" boxShadow="md" borderRadius="md" p="4">
              <Heading as="h3" size="md" mb={2}>
                Incentive
              </Heading>
              <Text>Incentive: {incentive}</Text>
            </Box>
          </Box>

          <Box mb={4} mt={"50px"}>
            <Heading as="h3" size="md" mb={2}>
              Working Performance Graph
            </Heading>
            <Line data={performanceData} options={options} />
          </Box>

          <Box>
            <Heading as="h3" size="md" mb={2} mt={"50px"}>
              Holidays
            </Heading>
            <Box
              display={"grid"}
              gridTemplateColumns={"repeat(5, 1fr)"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"20px"}
              mt={"20px"}
            >
              {holidays.map((holiday, index) => (
                <Box
                  key={index}
                  bg="green.100"
                  boxShadow="md"
                  borderRadius="md"
                  p="4"
                >
                  <Heading as="p" size="md">
                    {holiday}
                  </Heading>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default WorkingPerformance;
