import { React, useContext, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  Select,
  Text,
  VStack,
  Wrap,
  Heading,
  List,
  ListIcon,
  OrderedList,
  UnorderedList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  AspectRatio,
  useDisclosure,
  FormLabel,
  FormControl,
  Stack,
  Checkbox,
  useColorModeValue,
  color,
  useToast,
} from "@chakra-ui/react";

import b2b2 from "../assets/b2b2.svg";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction, handleVendorLogin } from "../../Redux/Singin/Signin.Action";

const VendorLogin = () => {
  const [form, setForm] = useState({ VID: "", password: "" });

  const toast = useToast();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const LogInHandle = (e) => {
    e.preventDefault();

    dispatch(handleVendorLogin(form, navigate));

    setForm({
      VID: "",
      password: "",
    });
  };

  return (
    <>
      <Box
        bgImage={""}
        style={{
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",

          backgroundColor: "#FFB91D",
          height: "100vh",
        }}
        flexDirection={{
          base: "column",
          md: "row",
          lg: "row",
        }}
        p={"30px 0px"}
      >
        <VStack
          bgImage={b2b2}
          style={{
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
            backgroundSize: "cover",
            // backgroundPosition: "center",
            boxSizing: "border-box",
            margin: "auto",
            height: "80vh",
          }}
          className="container"
          width={{ base: "80%", md: "50%", lg: "50%" }}
          padding={"10px"}
        >
          <Box
            className="container"
            rounded={"lg"}
            // bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
            mt={"50px"}
            width={{ base: "100%", md: "80%", lg: "80%" }}
            backgroundColor={"#FFFFFF"}
            color={"black"}
          >
            <Heading color={"black"} fontFamily={"serif"}>Vendor Sign In</Heading>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>VID</FormLabel>
                <Input
                  type="text"
                  name="VID"
                  value={form.VID}
                  onChange={handleChange}
                  isRequired
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  isRequired
                />
              </FormControl>

              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.400"}>Forgot password?</Link>
              </Stack>

              <Button
                bg={"#FFB91D"}
                color={"white"}
                _hover={{
                  bg: "orange",
                }}
                onClick={LogInHandle}
              >
                Sign In
              </Button>
            </Stack>
            <Box mt={"20px"}>
              <Text>
                Not Register?{" "}
                <Link to={"/vendor-signup"}>SignUp</Link>
              </Text>
            </Box>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default VendorLogin;
