import React, { useState } from "react";
import {
  Box,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Stack,
  Button,
  List,
  ListItem,
  Text,
  Spacer,
  Center,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";

const paymentData = [
  {
    id: 1,
    companyName: "redmi",
    date: "2023-08-10",
    transactionNumber: "T123",
    amount: 100,
    method: "Credit Card",
  },
  {
    id: 2,
    companyName: "redmi",
    date: "2023-08-11",
    transactionNumber: "T124",
    amount: 150,
    method: "PayPal",
  },
  {
    id: 3,
    companyName: "redmi",
    date: "2023-08-12",
    transactionNumber: "T125",
    amount: 200,
    method: "Bank Transfer",
  },
  {
    id: 4,
    companyName: "redmi",
    date: "2023-08-12",
    transactionNumber: "T125",
    amount: 200,
    method: "Bank Transfer",
  },
  {
    id: 5,
    companyName: "redmi",
    date: "2023-08-12",
    transactionNumber: "T125",
    amount: 200,
    method: "Bank Transfer",
  },
];

function Ledger() {
  const [currentPage, setCurrentPage] = useState(1);
  const elementsPerPage = 5;
  const totalPages = Math.ceil(paymentData.length / elementsPerPage);

  const startIndex = (currentPage - 1) * elementsPerPage;
  const slicedData = paymentData.slice(
    startIndex,
    startIndex + elementsPerPage
  );

  const nextPage = () => {
    setCurrentPage(currentPage === totalPages ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage === 1 ? currentPage : currentPage - 1);
  };

  const cumulativeAmounts = {};
  paymentData.forEach((payment) => {
    if (cumulativeAmounts.hasOwnProperty(payment.transactionNumber)) {
      cumulativeAmounts[payment.transactionNumber] += payment.amount;
    } else {
      cumulativeAmounts[payment.transactionNumber] = payment.amount;
    }
  });

  return (
    <Box bg="gray.100" m="4" p="4">
      <Card bg="white" p="8" boxShadow="lg" borderRadius="md">
        <Heading as="h2" mb="4" align="left" mt={4} pl={4} color="gray.600">
          Payment Ledger
        </Heading>
        <Divider mb={4} style={{ opacity: "0.2" }} />
        <Box p="4">
          <Table variant="striped" colorScheme="teal">
            
            <Thead>
              <Tr>
              <Th>Company Name</Th>
                <Th>Transaction Number</Th>
                <Th>Cumulative Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(cumulativeAmounts).map((transactionNumber) => (
                <Tr key={transactionNumber}>
                  <Td>{paymentData.find(payment => payment.transactionNumber === transactionNumber)?.companyName}</Td>
                  <Td>{transactionNumber}</Td>
                  <Td>{cumulativeAmounts[transactionNumber]}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box mt="4" textAlign="center">
            <Button
              colorScheme="orange"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              colorScheme="orange"
              ml="2"
              onClick={nextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default Ledger;
