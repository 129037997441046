import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom';
import AppContextProvider, {} from "./Context/AppContext"
import { Provider } from 'react-redux';
import { store } from './Store/Store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    
    <ChakraProvider>
      <Provider store={store} >
      <BrowserRouter>
          
            <App />
          
        </BrowserRouter>
        </Provider>
      </ChakraProvider>
    
  
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
