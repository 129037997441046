import { Box } from "@chakra-ui/react";
import Header from "./Header";
import DocumentVerification from "./DocumentVerification";
import PaymentDetails from "./PaymentDetails";
import Ledger from "./Ledger";
import History from "./History";
import { handleGetUser } from "../../../Redux/Profile/Profile.Action";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

function UserProfile() {
  const dispatch = useDispatch();
  const { getUserData } = useSelector((store) => store.Profile);

  useEffect(() => {
    dispatch(handleGetUser);
  }, []);

  return (
    <Box>
      <Header />
      <DocumentVerification />
      {getUserData?.userStatus == "Approved" && <PaymentDetails />}
      {getUserData?.userStatus == "Approved" && <Ledger />}
      {/* <History/> */}
    </Box>
  );
}

export default UserProfile;
