import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Button,
} from "@chakra-ui/react";
import Header from "./Header";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { handleChangeUserPassword } from "../../../Redux/Profile/Profile.Action";

function UserChangePassword() {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(handleChangeUserPassword(formData));

    setFormData({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  return (
    <Box>
      <Header />
      <Box bg="gray.100" p="4" m="4">
        <Box bg="white" boxShadow="lg" borderRadius="md" p="8">
          <Heading fontFamily={"serif"}>Change Password</Heading>
          <Box
            w={"30%"}
            m={"auto"}
            mt={"30px"}
            border={"1px solid #ddd"}
            p={"20px"}
          >
            <FormControl>
              <FormLabel>Old Password</FormLabel>
              <Input
                type="password"
                placeholder="old password"
                name="oldPassword"
                value={formData.oldPassword}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl mt={"10px"}>
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                placeholder="new password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl mt={"10px"}>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                placeholder="confirm password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </FormControl>
            <Button
              onClick={handleSubmit}
              width={"100%"}
              colorScheme="white"
              bg={"#FFB91D"}
              mt={"20px"}
              _hover={{ bgColor: "orange" }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UserChangePassword
