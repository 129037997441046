import React from 'react'

import Home5 from '../Home5/Home5'
import Home6 from '../FundRaising/component/component/Home6'
import Top_fundraising from '../FundRaising/component/component/Top_fundraising'
import Navbar_AL from '../../Navbar_AL'
import Footer from '../../Footer'
const Fundraising = () => {
    return (
        <>
            <Navbar_AL/>
            <Top_fundraising />
            <Home5 />
            <Home6 />
            <Footer/>
        </ >
    )
}

export default Fundraising