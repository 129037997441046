import {
  Box,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Text,
} from "@chakra-ui/react";
import { FiBell } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { RiLockPasswordFill, RiProfileFill } from "react-icons/ri";
import { BsCheckCircle, BsPersonFillCheck } from "react-icons/bs";
import { MdEditDocument, MdPrivacyTip } from "react-icons/md";
import { useEffect, useState } from "react";
import { IoPersonCircleSharp } from "react-icons/io5";
import { SiJirasoftware } from "react-icons/si";
import { HiReceiptRefund } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleGetUser } from "../../../Redux/Profile/Profile.Action";

function Header() {
  const isVerified = true;
  const approved = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getUserData } = useSelector((store) => store.Profile);

  useEffect(() => {
    dispatch(handleGetUser);
  }, []);

  const notificationData = [
    { type: "Email", date: "2023-08-10", message: "New email received" },
    {
      type: "SMS",
      date: "2023-08-11",
      message: "Reminder: appointment tomorrow",
    },
    {
      type: "Push Notification",
      date: "2023-08-12",
      message: "New message from friend",
    },
  ];

  const reversedData = notificationData.slice().reverse();

  console.log("userData", getUserData);
  


  return (
    <Box bg="#FFB91D" px="3" color="white">
      <Flex alignItems="center" justifyContent="space-between">
        <Box>
          <Flex alignItems="center">
            <Heading as="h1" ml="8">
              User Profile
            </Heading>

            {getUserData?.userStatus=="Approved" && (
              <Box ml="2" color="blue.500" fontWeight="bold" fontSize="1rem">
                <BsCheckCircle />
              </Box>
            )}
          </Flex>
        </Box>
        <Box
         display={"flex"}
          alignItems="center"
          justifyContent="center"
          marginTop="2"
          marginBottom={"1"}
        >
          <Box fontWeight={"bold"} mr={"10px"}>{getUserData?.Name}</Box>
          <Box>
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<FiBell />}
                _focus={{ boxShadow: "none" }}
                _active={{ bg: "transparent" }}
                variant="outline"
              />
              <MenuList>
                {reversedData?.map((notification, index) => (
                  <MenuItem key={index}>
                    <Box color={"black"}>{notification.message}</Box>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>

          
          <Box>
            <Menu>
              <MenuButton as={Avatar} mx="8" cursor="pointer" src={getUserData?.image} />
              <MenuList bg="#FFB91D" color="white">
                <MenuItem bg="#FFB91D">
                  <BsPersonFillCheck size={"18px"} />
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    <Link to="/user-update-profile">Update Profile</Link>
                  </span>
                </MenuItem>
                <MenuItem bg="#FFB91D">
                  <RiLockPasswordFill size={"18px"} />
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    <Link to="/user-change-password">Change Password</Link>
                  </span>
                </MenuItem>
                {getUserData?.userStatus=="Approved" &&(<MenuItem bg="#FFB91D">
                  <MdPrivacyTip size={"18px"} />
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </span>
                </MenuItem>)}
                {getUserData?.userStatus=="Approved" && (<MenuItem bg="#FFB91D">
                  <HiReceiptRefund size={"18px"} />
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    <Link to="/refund-policy">Refund Policy</Link>
                  </span>
                </MenuItem>)}
                {getUserData?.userStatus=="Approved" && (
                  <MenuItem bg="#FFB91D">
                  <MdPrivacyTip size={"18px"} />
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    <Link to="/user-complaint">Complaints</Link>
                  </span>
                </MenuItem>
                )}
                {getUserData?.userStatus=="Approved" && (
                  <MenuItem bg="#FFB91D">
                    <SiJirasoftware size={"18px"} />
                    <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                      <Link to="#">Mini Software</Link>
                    </span>
                  </MenuItem>
                )}

                <MenuItem
                  alignItems="center"
                  bg="#FFB91D"
                  onClick={() => {
                    sessionStorage.clear();
                    navigate("/");
                  }}
                >
                  <FiLogOut size={"16px"} />
                  <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                    Logout
                  </span>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
export default Header;
