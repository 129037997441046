import React from 'react'
import Layout from './Layout'

const Privacy_RefundPolicy = () => {
  return (
    <Layout>
      Privacy & Refund Policy
    </Layout>
  )
}

export default Privacy_RefundPolicy