import { Box } from "@chakra-ui/react";
import Header from "./Header";
import DocumentVerification from "./DocumentVerification";
import DailyWorking from "./DailyWorking";
import PaymentDetails from "./PaymentDetails";
import WorkingPerformance from "./WorkingPerformance";
import UserRegistration from "./UserRegistration";
import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux"
import { handleGetVendor } from "../../../Redux/Profile/Profile.Action";

function VendorProfile() {
  const dispatch=useDispatch()
  const {getVendorData}=useSelector((store)=>store.Profile)

  // const userDetails=JSON.parse(sessionStorage.getItem("userDetails"))

  useEffect(()=>{
    dispatch(handleGetVendor)
  },[])

  return (
    <Box>
      <Header />
      <Box>
        <DocumentVerification/>
      </Box>
      {getVendorData?.accountStatus=="Approved" && (<Box>
        <DailyWorking/>
        <PaymentDetails/>
      </Box>)}

      {getVendorData?.accountStatus=="Approved" && (<Box>
        <WorkingPerformance/>
      </Box>)}
    </Box>
  );
}

export default VendorProfile;
