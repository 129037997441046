import { Box, GridItem, Grid, Text, Flex } from "@chakra-ui/react";
import { useState } from "react";
import "../styles/NavbarStyles/BusinessSetup.css";
import { Link } from "react-router-dom";
import {
  FaChevronDown,
  FaChevronRight,
  FaChevronUp,
  FaFileSignature,
} from "react-icons/fa";
import { RiMoneyPoundBoxFill } from "react-icons/ri";

const FundRaising = () => {
  const [isActive, setIsActive] = useState(false);
  const [downArrow, setDownArrow] = useState(false);
  const [hoverbox, setHoverbox] = useState(1);

  const toggleFunc = () => {
    setIsActive((prev) => !prev);
  };

  const ChangeActive = () => {
    setIsActive(false);
  };

  return (
    <Box>
      <Box
        onMouseEnter={() => setDownArrow(true)}
        onMouseLeave={() => setDownArrow(false)}
        className="Trademark_Menu"
        onClick={() => toggleFunc()}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "7px",
        }}
      >
        {/* FUNDRAISING */} LONE
        {isActive ? (
          <span>
            <FaChevronUp size={"18px"} />
          </span>
        ) : (
          <span>
            <FaChevronDown size={"18px"} />
          </span>
        )}
      </Box>
      {isActive ? (
        <Box
          mt="100px"
          className="hover_Box_Container1"
          onMouseLeave={() => ChangeActive()}
          backgroundColor={"gray.100"}
        >
          <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            gap={"20px"}
          >
            <Flex
              direction={"column"}
              gap={"10px"}
              w={"50%"}
              textAlign={"left"}
            >
              <Box
                fontSize={"130%"}
                fontWeight={"bold"}
                color={"gray.500"}
                p={"10px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                _hover={{ background: "#FFFFFF", color: "black" }}
                borderRadius={"5px"}
                onMouseEnter={() => setHoverbox(1)}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <RiMoneyPoundBoxFill size={"22px"}/>
                  <Box>LONE</Box>
                </Box>
                <Box>
                  <FaChevronRight />
                </Box>
              </Box>
            </Flex>
            <Box w={"50%"} textAlign={"left"} p={"0px 20px"}>
              <Box
                display={hoverbox == 1 ? "block" : "none"}
                backgroundColor={"#FFFFFF"}
                borderRadius={"5px"}
                p={"10px 20px"}
              >
                <Text fontSize={"18px"} color={"black"}>
                  LONE
                </Text>
                <hr style={{ marginTop: "-15px", marginBottom: "10px" }} />
                <Link to={"/fundraising"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    {/* Fundraising */}
                    Business Loan Documentation
                  </Text>
                </Link>
                <Link to={"/pitch_deck"}>
                  <Text
                    onClick={() => ChangeActive()}
                    className="hoverBoxHeadingText_container_child"
                  >
                    {/* Pitch Deck */}
                    Balance Sheet
                  </Text>
                </Link>
              </Box>
            </Box>
          </Flex>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export { FundRaising };

// {/* <Grid
//             gridTemplateColumns={{ base: "repeat(3,33%)" }}
//             width="90%"
//             margin="auto"
//           >
//             <GridItem className="grid_item_in_hovered_div">
//               <Box className="hoverBoxHeadingText">
//                 {/* Fundraising */}
//                 LONE
//               </Box>

//               <Box className="hoverBoxHeadingText_container">
//                 <Link to={"/fundraising"}>
//                   <Text
//                     onClick={() => ChangeActive()}
//                     className="hoverBoxHeadingText_container_child"
//                   >
//                     {/* Fundraising */}
//                     Business Loan Documentation
//                   </Text>
//                 </Link>
//                 <Link to={"/pitch_deck"}>
//                   <Text
//                     onClick={() => ChangeActive()}
//                     className="hoverBoxHeadingText_container_child"
//                   >
//                     {/* Pitch Deck */}
//                     Balance Sheet
//                   </Text>
//                 </Link>
//               </Box>
//             </GridItem>
//           </Grid> */}
