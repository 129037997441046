import { Box } from "@chakra-ui/react";
import Home6 from "./Home6";
import Home5 from "./Home5";
import { HeaderSection } from "./HeaderSection";
import Navbar_AL from "../../Navbar_AL";
import Footer from "../../Footer";

const LimitedLiabilityPartnership = () => {
    return (
        <Box>
            <Navbar_AL/>
            <HeaderSection/>
            <Home5/>
            <Home6 />
            <Footer/>
        </Box>
    );
};

export { LimitedLiabilityPartnership }