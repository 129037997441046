import React from 'react'
import Top_gst_rodtep from '../GST RoDTEP/component/Top_gst_rodtep'
import Home5 from '../Home5/Home5'
import Home6 from '../GST RoDTEP/component/Home6'
import Navbar_AL from '../../Navbar_AL'
import Footer from '../../Footer'

const GST__rodtep = () => {
    return (
        <>
           <Navbar_AL/>
            <Top_gst_rodtep />
            <Home5 />
            <Home6 />
            <Footer/>
        </>
    )
}

export default GST__rodtep