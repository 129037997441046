import React from 'react'
import Top_gst_ragistration from '../GST registration/Componentes/Top_gst_ragistration'
import Home6 from '../GST registration/Componentes/Home6'
import Home5 from '../Home5/Home5'
import Navbar_AL from '../../Navbar_AL'
import Footer from '../../Footer'

const GST_registration = () => {
    return (
        <>
            <Navbar_AL/>
            <Top_gst_ragistration />
            <Home5 />
            <Home6 />
            <Footer/>
        </>
    )
}

export default GST_registration