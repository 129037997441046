import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import google from "../assets/google.png";
import trustpilot from "../assets/trustpiliot.png";
import mouthshut from "../assets/mouthshut.png";
import "../../styles/Home.css";
import { Link } from "react-router-dom";

let toogle = 0;

export default function Home4() {
  const stylePropP3 = { fontSize: "1xl", color: "black" };

  return (
    <Box w="90%" ml={{ lg: "5vw", md: "6vw", sm: "6vw", base: "6vw" }}>
      <Flex className="home4flex" mt={"40px"}>
        <Box textAlign={"left"}  w={["100%","100%","100%","50%"]}>
          <Text fontSize={"18px"} color="#fdc400">
            SETUP YOUR OWN COMPANY
          </Text>
          <h1
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              textShadow: "0px 2px, 2px 0px, 2px 2px",
            }}
          >
            Donate for our NGO
          </h1>
          <Text {...stylePropP3} mt={"30px"}>
            ✓ Get your application submitted within 10 Days
          </Text>

          <Text {...stylePropP3} mt={"-10px"}>
            ✓ Transparent process, thorough follow up and regular updates
          </Text>

          <Text>
            <Text color={"#fdc400"} fontSize="20px">
              15,000+
            </Text>
            OPC registrations since 2011
          </Text>

          <Flex
            direction={["column", "row", "row", "row"]}
            align={["center", "center", "center", "flex-start"]}
            justify={["center", "center", "center", "flex-start"]}
            gap={"30px"}
            mb={"30px"}
            mt={"50px"}
          >
            {/* className="home4hstack" */}
            <Box>
              <Image h="45px" src={mouthshut} alt=""></Image>
            </Box>
            <Box>
              <Image h="45px" src={trustpilot} alt=""></Image>
            </Box>
            <Box>
              <Image h="45px" src={google} alt=""></Image>
            </Box>
          </Flex>
        </Box>

        <VStack
          bgImage={
            "https://media.istockphoto.com/id/1355017147/photo/group-of-teamwork-high-five-team-together-hands-raise-up-power-partner-diversity-multiethnic.jpg?b=1&s=170667a&w=0&k=20&c=K4qf9Wz8FqSLX1iawNS99Nk4GTEPxdKieiVs4s8w-0k="
          }
          bgSize="cover"
          className="home4vstack"
          w={["100%","100%","100%","50%"]}
        >
          <VStack
            gap="20px"
            // border="1px solid red"
            borderRadius="20px"
            minW="50%"
            minH="70%"
            p="20px"
            bg="rgba(255, 255, 255, 0.699)"
          >
            <Text fontSize="2xl">Get Started</Text>
            <Input bg="white" placeholder="Enter your Email*"></Input>
            <Input bg="white" placeholder="Enter your Mobile number*"></Input>
            <Select bg="white">
              <option value="">Select City</option>
              <option value="">city1</option>
              <option value="">city2</option>
              <option value="">city3</option>
            </Select>
            <Flex
              justifyContent={"center"}
              alignItems={"flex-start"}
              gap={"5px"}
            >
              <Text>Get easy updates through </Text>
              <Box w="5%" cursor="pointer">
                <Image
                  w={"100%"}
                  height={"100%"}
                  src="https://img.icons8.com/color/1x/whatsapp--v2.gif"
                ></Image>
              </Box>
              <Text>Whatsapp</Text>
              <Box w={"8%"}>
                <Image
                  cursor={"pointer"}
                  w={"100%"}
                  height={"100%"}
                  src="https://cdn-icons-png.flaticon.com/128/5683/5683514.png"
                  onClick={(e) =>
                    (e.target.src =
                      toogle++ % 2
                        ? "https://cdn-icons-png.flaticon.com/128/5683/5683501.png"
                        : "https://cdn-icons-png.flaticon.com/128/5683/5683514.png")
                  }
                  alt=""
                ></Image>
              </Box>
            </Flex>
            <Button bg="gold">
              <Link to="#">TALK TO REGISTRATION EXPERT</Link>
            </Button>
          </VStack>
        </VStack>
      </Flex>
    </Box>
  );
}
