import {
  ERROR_Profile,
  GET_VENDOR_DATA,
  GET_VENDOR_ERROR,
  GET_VENDOR_Loading,
  Get_Profile,
  LOADING_Profile,
  SUCCESS_Profile,
  GET_USER_DATA,
  GET_USER_Loading,
  GET_USER_ERROR
} from "./Profile.Type";

const initialState = {
  loading: false,
  profileData: [],
  error: false,
  token: localStorage.getItem("token") || null,

  getVendorLoading: false,
  getVendorError: false,
  getVendorData: {},

  getUserLoading: false,
  getUserError: false,
  getUserData: {},
};

export const Profile_Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_Profile: {
      return { ...state, loading: true, error: false };
    }
    case SUCCESS_Profile: {
      return {
        ...state,
        error: false,
        loading: false,
        profileData: payload,
        token: payload.token,
      };
    }
    case ERROR_Profile: {
      return { ...state, error: true, loading: false, profileData: payload };
    }
    case Get_Profile: {
      return { ...state, error: false, loading: false, profileData: payload };
    }

    case GET_VENDOR_Loading: {
      return { ...state, getVendorLoading: true, getVendorError: false };
    }

    case GET_VENDOR_DATA: {
      return {
        ...state,
        getVendorLoading: false,
        getVendorError: false,
        getVendorData: payload,
      };
    }

    case GET_VENDOR_ERROR: {
      return {
        ...state,
        getVendorLoading: false,
        getVendorError: true,
      };
    }

    case GET_USER_Loading: {
        return { ...state, getUserLoading: true, getUserError: false };
      }
  
      case GET_USER_DATA: {
        return {
          ...state,
          getUserLoading: false,
          getUserError: false,
          getUserData: payload,
        };
      }
  
      case GET_USER_ERROR: {
        return {
          ...state,
          getUserLoading: false,
          getUserError: true,
        };
      }
    default: {
      return state;
    }
  }
};
