import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input
} from "@chakra-ui/react";
import { useState } from "react";
import {useDispatch} from "react-redux"
import { handleCreateUser } from "../../../Redux/Profile/Profile.Action";

function UserRegistration() {
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    aadharNumber: "",
    panNo: "",
    address: "",
    city: "",
    state: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch=useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    
    dispatch(handleCreateUser(formData, onClose))

    setFormData({
        name: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        aadharNumber: "",
        panNo: "",
        address: "",
        city: "",
        state: "",
    })
  };

  return (
    <Box>
      <Button colorScheme={"orange"} onClick={onOpen}>
        + Create User
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              display={"grid"}
              gridTemplateColumns={"repeat(2, 1fr)"}
              alignItems={"center"}
              gap={"20px"}
            >
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input type="text" name="name" value={formData.name} onChange={handleChange}/>
              </FormControl>
              <FormControl>
                <FormLabel>Company Name</FormLabel>
                <Input type="text" name="companyName" value={formData.companyName} onChange={handleChange}/>
              </FormControl>
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input type="email" name="email" value={formData.email} onChange={handleChange}/>
              </FormControl>
              <FormControl>
                <FormLabel>Mobile Number</FormLabel>
                <Input type="text"  name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}/>
              </FormControl>
              <FormControl>
                <FormLabel>Aadhar Number</FormLabel>
                <Input type="text" name="aadharNumber" value={formData.aadharNumber} onChange={handleChange}/>
              </FormControl>
              <FormControl>
                <FormLabel>Pan Number</FormLabel>
                <Input type="text" name="panNo" value={formData.panNo} onChange={handleChange}/>
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input type="text" name="address" value={formData.address} onChange={handleChange}/>
              </FormControl>
              <FormControl>
                <FormLabel>City</FormLabel>
                <Input type="text" name="city" value={formData.city} onChange={handleChange}/>
              </FormControl>
              <FormControl>
                <FormLabel>State</FormLabel>
                <Input type="text" name="state" value={formData.state} onChange={handleChange}/>
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme={"orange"} onClick={handleSubmit}>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default UserRegistration;
