import { Box, Button, Input, FormLabel, FormControl, Heading, Textarea} from "@chakra-ui/react";
import { useState } from "react";
import {useDispatch} from "react-redux"
import Header from "./Header";
import { handleVendorComplaint } from "../../../Redux/Profile/Profile.Action";


function VendorComplaint() {
  const [formData, setFormData] = useState({
    Name: "",
    GST_Number: "",
    Notes: "",
    complaintDetails: "",
  });

  const dispatch=useDispatch()

  const handleChange=(e)=>{
    const {name, value}=e.target;
    setFormData({...formData, [name]:value})

  }

  const handleSubmit=(e)=>{
    e.preventDefault()

    console.log(formData)
    dispatch(handleVendorComplaint(formData))


    setFormData({
        Name: "",
        GST_Number: "",
        Notes: "",
        complaintDetails: "",
    })


  }

  return (
    <Box>
      <Header />
      <Box bg="gray.100" p="4" m="4">
        <Box bg="white" boxShadow="lg" borderRadius="md" p="8">
          <Heading fontFamily={"serif"}>Complaint Form</Heading>
          <Box
            w={"30%"}
            m={"auto"}
            mt={"30px"}
            border={"1px solid #ddd"}
            p={"20px"}
          >
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="Name"
                name="Name"
                value={formData.Name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl mt={"10px"}>
              <FormLabel>GST Number</FormLabel>
              <Input
                type="text"
                placeholder="GST Number"
                name="GST_Number"
                value={formData.GST_Number}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl mt={"10px"}>
              <FormLabel>Notes</FormLabel>
              <Input
                type="text"
                placeholder="Notes"
                name="Notes"
                value={formData.Notes}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mt={"10px"}>
              <FormLabel>Complaint Details</FormLabel>
              <Textarea
                type="text"
                placeholder="Complaint Details"
                name="complaintDetails"
                value={formData.complaintDetails}
                onChange={handleChange}
              />
            </FormControl>
            <Button
              onClick={handleSubmit}
              width={"100%"}
              colorScheme="white"
              bg={"#FFB91D"}
              mt={"20px"}
              _hover={{ bgColor: "orange" }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default VendorComplaint;
