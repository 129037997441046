// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg width=%2711%27 height=%278%27 viewBox=%270 0 11 8%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M1.23065 4.08001L4.06965 6.91901L9.74764 0.835449%27 stroke=%27%231E1E1E%27 stroke-width=%271.29783%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E%0A", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pricelistnormal_listview__XrgpC{\n    list-style: disc inside url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    text-align: left;\n    font-size: 12px;\n    margin-top: 17px;\n    font-family: 'Poppins', sans-serif;\n}\n\n@media all and (min-width:0em) and (max-width:48em) {\n    .pricelistnormal_listview__XrgpC{\n        font-size: 10px;\n        margin-top: 13px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/styles/pricelistnormal.module.css"],"names":[],"mappings":"AAAA;IACI,+DAA0T;IAC1T,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,kCAAkC;AACtC;;AAEA;IACI;QACI,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".listview{\n    list-style: disc inside url(\"data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.23065 4.08001L4.06965 6.91901L9.74764 0.835449' stroke='%231E1E1E' stroke-width='1.29783' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A\");\n    text-align: left;\n    font-size: 12px;\n    margin-top: 17px;\n    font-family: 'Poppins', sans-serif;\n}\n\n@media all and (min-width:0em) and (max-width:48em) {\n    .listview{\n        font-size: 10px;\n        margin-top: 13px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listview": "pricelistnormal_listview__XrgpC"
};
export default ___CSS_LOADER_EXPORT___;
