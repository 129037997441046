import React, { useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Input,
  Button,
  Divider,
  Heading,
  TableContainer,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Checkbox,
  ModalFooter,
} from '@chakra-ui/react';
import { ChevronDownIcon, FaTrash } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  postPurchaseAction
} from "../../../../../Redux/Purchase/purchase.action";
import Company_name from '../../Company_name/Company_name';
import Slidebar from '../../Slidebar/Slidebar';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, FaPlus } from '@chakra-ui/icons';

const userDetails = JSON.parse(sessionStorage.getItem("companyDetails")) ? JSON.parse(sessionStorage.getItem("companyDetails")) : null;

const AddPurchase = () => {
  const [rows, setRows] = useState([{ id: 1 }]);
  const { firmId } = useSelector((store) => store.FirmRegistration);
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsData, setSelectedItemsData] = useState([]);
  const [showMfgExpDate, setShowMfgExpDate] = useState(false);

  const navigate = useNavigate();
  const Company = {
    name: "Company Name",
  };

  const calculateTotalPrice = (quantity, itemPrice, gst) => {
    const parsedQuantity = parseFloat(quantity);
    const parsedItemPrice = parseFloat(itemPrice);
    const parsedGST = parseFloat(gst);

    if (!isNaN(parsedQuantity) && !isNaN(parsedItemPrice)) {
      // Calculate total price and add GST if applicable
      const totalWithoutGST = parsedQuantity * parsedItemPrice;
      const totalWithGST = parsedGST
        ? totalWithoutGST + (totalWithoutGST * (parsedGST / 100))
        : totalWithoutGST;

      // Return the total price as a number
      return totalWithGST;
    }

    return 0; // Return 0 if any of the input values is not a number
  };

  const handleInputChangeFromItemsTable = (e, field, rowId) => {
    const { value } = e.target;
    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        if (field === 'quantity') {
          row.quantity = value;
        }
        if (field === 'itemPrice') {
          row.itemPrice = value;
        }
        if (field === 'gst') {
          row.gst = value;
        }
        const parsedQuantity = parseFloat(row.quantity);
        const parsedItemPrice = parseFloat(row.itemPrice);
        const gstInputValue = row.gst;
        const parsedGST = parseFloat(gstInputValue?.match(/\d+/)[0]);
        const totalPrice = calculateTotalPrice(parsedQuantity, parsedItemPrice, parsedGST);
        let gstAmount = (row.quantity * row.itemPrice) * (parsedGST / 100);
        return {
          ...row,
          [field]: value,
          gstAmount: gstAmount,
          totalPrice: totalPrice.toFixed(2), // Round to 2 decimal places for display
        };
      }
      return row;
    });

    setRows(updatedRows);
  };

  const addRow = () => {
    const newItem = { id: rows.length + 1, itemName: '', itemCode: '', hsnCode: '', serialNo: '', batchNo: '', modelNo: '', expDate: '', mfgDate: '', mrp: '', size: '', qty: '', unit: '', priceUnit: '', rate: '', amount: 0, taxPercentage: 0, taxAmount: 0, showRowOptions: false };
    setRows([...rows, newItem]);
  };

  const modal2 = useDisclosure();
  const handleItemAdd = () => {
    modal2.onOpen();
  };

  const deleteRow = (rowId) => {
    const updatedRows = rows.filter((row) => row.id !== rowId);
    setRows(updatedRows);
  };

  const calculateamount = () => {
    let total = 0;
    rows.forEach((row) => {
      const amount = parseFloat(row.amount) || 0;
      if (!isNaN(amount)) {
        total += amount;
      }
    });
    return total.toFixed(2);
  };

  const calculateTaxAmount = (row) => {
    const taxPercentage = parseFloat(row.taxPercentage) || 0;
    const amount = parseFloat(row.amount) || 0;
    if (!isNaN(taxPercentage) && !isNaN(amount)) {
      return ((amount * taxPercentage) / 100).toFixed(2);
    }
    return 0;
  };

  const handleInputChange = (e, field, rowId) => {
    const { value } = e.target;
    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        if (field === 'gst') {
          row.gst = value;
        }
        return {
          ...row,
          [field]: value,
          rate: (parseFloat(value) + parseFloat(row.taxAmount)).toFixed(2),
          amount: (parseFloat(value) * parseFloat(row.qty)).toFixed(2),
          taxAmount: calculateTaxAmount({
            ...row,
            [field]: value,
          }),
        };
      }
      return row;
    });
    setRows(updatedRows);

    // Update the item object with additional fields
    const updatedItem = {
      ...item,
      item: updatedRows.map((row) => ({
        itemName: row.itemName,
        itemCode: row.itemCode,
        hsnCode: row.hsnCode,
        serialNo: row.serialNo,
        batchNo: row.batchNo,
        modelNo: row.modelNo,
        expDate: row.expDate,
        mfgDate: row.mfgDate,
        mrp: row.mrp,
        size: row.size,
        qty: row.qty,
        unit: row.unit,
        priceUnit: row.priceUnit,
        rate: row.rate,
        amount: row.amount,
        taxPercentage: row.taxPercentage,
        taxAmount: row.taxAmount,
      })),
    };
    setItem(updatedItem);
  };

  const stockData = useSelector((store) => store.stockReducer.getStockData);

  const handleTaxTypeChange = (e, rowId) => {
    const { value } = e.target;
    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        return {
          ...row,
          taxPercentage: parseFloat(value.split('@')[1]) || 0,
          taxAmount: calculateTaxAmount({
            ...row,
            taxPercentage: parseFloat(value.split('@')[1]) || 0,
          }),
        };
      }
      return row;
    });
    setRows(updatedRows);

    // Update the item object with additional fields
    const updatedItem = {
      ...item,
      item: updatedRows.map((row) => ({
        itemName: row.itemName,
        itemCode: row.itemCode,
        hsnCode: row.hsnCode,
        serialNo: row.serialNo,
        batchNo: row.batchNo,
        modelNo: row.modelNo,
        expDate: row.expDate,
        mfgDate: row.mfgDate,
        mrp: row.mrp,
        size: row.size,
        qty: row.qty,
        unit: row.unit,
        priceUnit: row.priceUnit,
        rate: row.rate,
        amount: row.amount,
        taxPercentage: row.taxPercentage,
        taxAmount: row.taxAmount,
      })),
    };
    setItem(updatedItem);
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });

    setSelectedItemsData((prevSelectedItemsData) => {
      const selectedItem = stockData.find((item) => item._id === itemId);
      if (selectedItem) {
        const existingItemIndex = prevSelectedItemsData.findIndex(
          (item) => item._id === itemId
        );
        if (existingItemIndex !== -1) {
          return prevSelectedItemsData.filter((item) => item._id !== itemId);
        } else {
          return [...prevSelectedItemsData, selectedItem];
        }
      }
      return prevSelectedItemsData;
    });
  };

  const handleAddRowOptions = (rowId) => {
    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        return {
          ...row,
          showRowOptions: !row.showRowOptions,
        };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleItemsAdd = () => {
    const updatedRows = rows.map((row) => {
      const selectedItem = selectedItemsData.find((item) => item._id === row.itemId);
      if (selectedItem) {
        return {
          ...row,
          itemName: selectedItem.brand,
          category: selectedItem.category,
          quantity: selectedItem.stockQuantity,
          unit: selectedItem.unit,
          itemPrice: selectedItem.cost,
          gst: selectedItem.gstRate,
          gstAmount: calculateTaxAmount({
            ...row,
            gst: selectedItem.gstRate,
          }),
          totalPrice: calculateTotalPrice(
            selectedItem.stockQuantity,
            selectedItem.cost,
            selectedItem.gstRate
          ).toFixed(2),
        };
      }
      return row;
    });

    setRows(updatedRows);
    setSelectedItems([]); // Clear the selected items
    setSelectedItemsData([]); // Clear the selected items data
    modal2.onClose();
  };

  const handleSubmit = () => {
    const data = { ...item, item: [...rows], totalAmount: calculateamount() };

    dispatch(postPurchaseAction(data, userDetails?.token, firmId));

    setRows([{ id: 1 }]);
  };

  const addMfgExpDate = () => {
    setShowMfgExpDate(!showMfgExpDate);
  };

  return (
    <>
      <Company_name company_name={Company.name} />
      <Flex>
        <Slidebar />
        <Box bg="white" py="4" px="6" minH="80vh" flex="1" boxShadow="md">
          <Flex
            margin='15px'
            justifyContent='space-between'
            p='4'
            boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px'
          >
            <Heading size='md' color='gray.500'> Add Purchase Item </Heading>
            <Flex>
              <Text
                mr='4'
                textAlign='right'
                mt='1'
                fontWeight='semibold'
              >
                BillNo:
              </Text>
              <Input
                ml='4'
                width='100%'
                type='text'
                placeholder='Enter Bill No.'
                size='sm'
                onChange={e => setItem(preVal => ({ ...preVal, billNo: e.target.value }))}
              />
              <Input
                ml='4'
                width='100%'
                type='date'
                placeholder='Select date'
                size='sm'
                onChange={e => setItem(preVal => ({ ...preVal, date: e.target.value }))}
              />
            </Flex>
          </Flex>

          <Box
            margin='15px'
            mt='4'
            p='2'
            boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px'
          >
            <Flex justifyContent='space-between' mt='1'>
              <Flex justifyContent='center'>
                <Text
                  mr='4'
                  textAlign='left'
                  mt='1'
                  fontWeight='semibold'
                >
                  Party Name :
                </Text>
                <Input
                  placeholder='Enter Party Name'
                  size='sm'
                  width='60%'
                  onChange={e => setItem(preVal => ({ ...preVal, partyName: e.target.value }))}
                />
              </Flex>
              <Flex justifyContent='center'>
                <Text
                  mr='4'
                  textAlign='left'
                  mt='1'
                  fontWeight='semibold'
                >
                  Phone Number:
                </Text>
                <Input
                  placeholder='Enter Contact Number'
                  size='sm'
                  type='number'
                  width='60%'
                  onChange={e => setItem(preVal => ({ ...preVal, contactNo: e.target.value }))}
                />
              </Flex>
              <Flex justifyContent='center'>
                <Text
                  mr='4'
                  textAlign='left'
                  mt='1'
                  fontWeight='semibold'
                >
                  Gst Number:
                </Text>
                <Input
                  placeholder='gstNumber'
                  size='sm'
                  type='text'
                  width='60%'
                  onChange={e => setItem(preVal => ({ ...preVal, gstNo: e.target.value }))}
                />
              </Flex>
            </Flex>
            <Divider />
            <Flex justifyContent='space-between' margin='15px'>
              <Heading size='sm'> Add Items</Heading>
              <Button
                onClick={addMfgExpDate}
                colorScheme='teal'
                size='sm'
                title='Add MFG and Exp Date'
              >
                + Add Row
              </Button>
            </Flex>
            <TableContainer
              bg="white"
              margin="15px"
              border="0.1px solid lightgray"
              boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th style={{ border: '1px solid lightgray' }}>Item Name</Th>
                    <Th style={{ border: '1px solid lightgray' }}>Category</Th>
                    {showMfgExpDate && (
                      <>
                        <Th style={{ border: '1px solid lightgray' }}>MFG Date</Th>
                        <Th style={{ border: '1px solid lightgray' }}>Exp Date</Th>
                      </>
                    )}
                    <Th style={{ border: '1px solid lightgray' }}>Quantity</Th>
                    <Th style={{ border: '1px solid lightgray' }}>Unit</Th>
                    <Th style={{ border: '1px solid lightgray' }}>Discount</Th>
                    <Th style={{ border: '1px solid lightgray' }}>Item Price</Th>
                    <Th style={{ border: '1px solid lightgray' }}>GST % </Th>
                    <Th style={{ border: '1px solid lightgray' }}>GST Amount  </Th>
                    <Th style={{ border: '1px solid lightgray' }}>Total Price</Th>
                    <Th style={{ border: '1px solid lightgray' }}>Actions</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {rows.map((row) => (
                    <Tr key={row.itemId}>
                      <Td style={{ border: '1px solid gray' }}>
                        <Input
                          variant='unstyled'
                          placeholder='Item Name'
                          value={row.itemName}
                          onClick={handleItemAdd}
                          onChange={(e) =>
                            handleInputChangeFromItemsTable(e, 'itemName', row.id)
                          }
                          required
                        />
                        {/* Add Item Modal */}
                        {/* ... */}
                      </Td>
                      <Td style={{ border: '1px solid gray' }}>
                        <Input
                          variant='unstyled'
                          placeholder='Category'
                          value={row.category}
                          onChange={(e) =>
                            handleInputChangeFromItemsTable(e, 'category', row.id)
                          }
                        />
                      </Td>
                      {showMfgExpDate && (
                        <>
                          <Td style={{ border: '1px solid gray' }}>
                            <Input
                              variant='unstyled'
                              placeholder='MFG Date'
                              value={row.mfgDate}
                              onChange={(e) =>
                                handleInputChangeFromItemsTable(e, 'mfgDate', row.id)
                              }
                            />
                          </Td>
                          <Td style={{ border: '1px solid gray' }}>
                            <Input
                              variant='unstyled'
                              placeholder='Exp Date'
                              value={row.expDate}
                              onChange={(e) =>
                                handleInputChangeFromItemsTable(e, 'expDate', row.id)
                              }
                            />
                          </Td>
                        </>
                      )}
                      <Td style={{ border: '1px solid gray' }}>
                        <Input
                          variant='unstyled'
                          placeholder='Quantity'
                          value={row.quantity}
                          onChange={(e) =>
                            handleInputChangeFromItemsTable(e, 'quantity', row.id)
                          }
                        />
                      </Td>
                      <Td width='120px' padding='0px 0px' style={{ border: '1px solid gray' }}>
                        <Select fontSize='15px' value={row.unit} variant='unstyled' placeholder='Unit'>
                          <option value="">None</option>
                          <option value="bags">BAGS(Bag)</option>
                          <option value="bottle">BOTTLE(Btl)</option>
                          <option value="box">BOX(Box)</option>
                          <option value="bundles">BUNDLES(Bdl)</option>
                          <option value="cans">CANS(can)</option>
                          <option value="cartons">CARTONS(Ctn)</option>
                          <option value="dogens">DOGENS(Dzn)</option>
                          <option value="grammes">GRAMMES(GM)</option>
                          <option value="kilograms">KILOGRAMS(KG)</option>
                          <option value="litters">LITTERS(Ltr)</option>
                          <option value="meaters">MEATERS(Mtr)</option>
                          <option value="mililiter">MILILITER(Ml)</option>
                          <option value="numbers">NUMBERS(Nos)</option>
                          <option value="packs">PACKS(Pac)</option>
                          <option value="pairs">PAIRS(Prs)</option>
                          <option value="pieces">PIECES(Pcs)</option>
                          <option value="quintals">QUINTALS(Qtl)</option>
                          <option value="rolls">ROLLS(Rol)</option>
                          <option value="squarefeet">SQUARE FEET(Sqf)</option>
                        </Select>
                      </Td>
                      <Td style={{ border: '1px solid gray' }}>
                        <Input
                          variant='unstyled'
                          placeholder='discount'
                          value={row.discount}
                          onChange={(e) =>
                            handleInputChangeFromItemsTable(e, 'batchNo', row.id)
                          }
                        />
                      </Td>
                      <Td style={{ border: '1px solid gray' }}>
                        <Input
                          variant='unstyled'
                          placeholder='item price'
                          value={row.itemPrice}
                          onChange={(e) =>
                            handleInputChangeFromItemsTable(e, 'itemPrice', row.id)
                          }
                        />
                      </Td>
                      <Td padding='0px 0px' width='130px' style={{ border: '1px solid gray' }}>
                        <Select
                          onChange={(e) =>
                            handleInputChangeFromItemsTable(e, 'gst', row.id)
                          }
                          value={row.gst} variant='unstyled' placeholder='GST'>
                          <option value='None'>None</option>
                          <option value='GST@0'>GST@0</option>
                          <option value='IGST@0'>IGST@0</option>
                          <option value='IGST@0.25%'>IGST@0.25%</option>
                          <option value='GST@0.25%'>GST@0.25%</option>
                          <option value='IGST@3%'>IGST@3%</option>
                          <option value='GST@3%'>GST@3%</option>
                          <option value='IGST@5%'>IGST@5%</option>
                          <option value='GST@5%'>GST@5%</option>
                          <option value='IGST@12%'>IGST@12%</option>
                          <option value='GST@12%'>GST@12%</option>
                          <option value='IGST@18%'>IGST@18%</option>
                          <option value='GST@18%'>GST@18%</option>
                          <option value='IGST@28%'>IGST@28%</option>
                          <option value='GST@28%'>GST@28%</option>
                          <option value='exmpt'>exmpt</option>
                        </Select>
                      </Td>
                      <Td style={{ border: '1px solid gray' }}>
                        <Input
                          variant='unstyled'
                          placeholder='gstAmount'
                          value={row.gstAmount}
                          onChange={(e) =>
                            handleInputChangeFromItemsTable(e, 'gstAmount', row.id)
                          }
                        />
                      </Td>
                      <Td style={{ border: '1px solid gray' }}>
                        <Input
                          variant='unstyled'
                          placeholder='total price'
                          value={row.totalPrice}
                          onChange={(e) =>
                            handleInputChangeFromItemsTable(e, 'totalPrice', row.id)
                          }
                        />
                      </Td>
                      <Td style={{ border: '1px solid gray' }}>
                       <DeleteIcon onClick={() => deleteRow(row.id)} />
                      </Td>
                      {showMfgExpDate && (
                        <>
                          <Td style={{ border: '1px solid gray' }}>
                            <Input
                              variant='unstyled'
                              placeholder='MFG Date'
                              value={row.mfgDate}
                              onChange={(e) =>
                                handleInputChangeFromItemsTable(e, 'mfgDate', row.id)
                              }
                            />
                          </Td>
                          <Td style={{ border: '1px solid gray' }}>
                            <Input
                              variant='unstyled'
                              placeholder='Exp Date'
                              value={row.expDate}
                              onChange={(e) =>
                                handleInputChangeFromItemsTable(e, 'expDate', row.id)
                              }
                            />
                          </Td>
                        </>
                      )}
                      {/* ... */}
                    </Tr>
                  ))}
                </Tbody>
                <Box marginLeft='0px'>
                  <Button onClick={addRow} color='green.400' sx={{ marginLeft: '0px', padding: '15px ', fontSize: '14px', border: '1px solid black', }} >+ ADD NEW ITEM</Button>
                </Box>
              </Table>
            </TableContainer>
            <Flex justifyContent='flex-end' margin='15px'>
              <Text mr='2'> Total Amount (₹): </Text>
              <Text fontSize='md' color='blue'>
                {calculateamount()}
              </Text>
            </Flex>
          </Box>
          <Flex margin='30px'>
            <Button mr='4' size='md'>
              Save & New
            </Button>
            <Button mr='4' size='md' onClick={handleSubmit}>
              Save
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default AddPurchase;