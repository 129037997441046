
export const LOADING_PARTIES_PROFITLOSS="partyprofitloss/LOADING_PARTIES_PROFITLOSS";

export const SUCCESS_PARTIES_PROFITLOSS="partyprofitloss/SUCCESS_PARTIES_PROFITLOSS";

export const ERROR_PARTIES_PROFITLOSS="partyprofitloss/ERROR_PARTIES_PROFITLOSS";

export const GET_PARTIES_PROFITLOSS="partyprofitloss/GET_PARTIES_PROFITLOSS";

export const UPDATE_PARTIES_PROFITLOSS="partyprofitloss/UPDATE_PARTIES_PROFITLOSS";

export const DELETE_PARTIES_PROFITLOSS="partyprofitloss/DELETE_PARTIES_PROFITLOSS";

export const SEARCH_PARTIES_PROFITLOSS="partyprofitloss/SEARCH_PARTIES_PROFITLOSS";

export const INDUVIDUAL_PARTY_PROFITLOSS="partyprofitloss/INDUVIDUAL_PARTY_PROFITLOSS";

export const GET_PARTY_ID="partyprofitloss/GET_PARTY_ID_PROFITLOSS";

