import React from 'react'
import Top_Ngo2 from '../Top/Top_Ngo2'
import Home5 from '../Home5/Home5'
import Home6 from '../Home6'
import Navbar_AL from '../../Navbar_AL'
import Footer from '../../Footer'

const Ngo_Compliance = () => {
    return (
        <>
            <Navbar_AL/>
            <Top_Ngo2 heading="Complications in NGO Compliance? No More!" text1="NGO compliance refers to the adherence to laws, regulations, and standards set by governments and other organisations that govern the operations and activities of NGOs." />
            <Home5 />
            <Home6 />
            <Footer/>
        </>
    )
}

export default Ngo_Compliance