export const LOADING_Profile = "profile/LOADING_Profile";
export const SUCCESS_Profile = "profile/SUCCESS_Profile";
export const ERROR_Profile = "profile/ERROR_Profile";
export const Get_Profile = "profile/GET_Profile";


export const GET_VENDOR_DATA="GET_VENDOR_DATA"
export const GET_VENDOR_Loading="GET_VENDOR_Loading"
export const GET_VENDOR_ERROR="GET_VENDOR_ERROR"

export const GET_USER_DATA="GET_USER_DATA"
export const GET_USER_Loading="GET_USER_Loading"
export const GET_USER_ERROR="GET_USER_ERROR"


