// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reviewslider_mySwiper__l0iAN{\n    width: 100%;\n    height: 200px;\n    font-family: 'Poppins', sans-serif;\n    z-index: -1;\n}\n\n@media all and (min-width:0em) and (max-width:48em) {\n    .reviewslider_mySwiper__l0iAN{\n        margin-left: 25px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/styles/reviewslider.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kCAAkC;IAClC,WAAW;AACf;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".mySwiper{\n    width: 100%;\n    height: 200px;\n    font-family: 'Poppins', sans-serif;\n    z-index: -1;\n}\n\n@media all and (min-width:0em) and (max-width:48em) {\n    .mySwiper{\n        margin-left: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mySwiper": "reviewslider_mySwiper__l0iAN"
};
export default ___CSS_LOADER_EXPORT___;
