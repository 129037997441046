import React from 'react'
import Top_gst_indirect_tax from '../GST Indirect Tax/component/component/Top_gst_indirect_tax'
import Home5 from '../Home5/Home5'
import Home6 from '../GST Indirect Tax/component/component/Home6'
const GST_indirect_tax = () => {
    return (
        <>
            <Top_gst_indirect_tax />
            <Home5 />
            <Home6 />
        </>
    )
}

export default GST_indirect_tax