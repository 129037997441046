import React from 'react'
import Top3 from '../Top/Top3'
import Home5 from "../Home5/Home5"
import Home6 from "./Home6"
import Navbar_AL from '../../Navbar_AL'
import Footer from '../../Footer'
const Close_the_Pvt_Ltd_Company = () => {
  return (
    <div>
          <Navbar_AL/>
          <Top3 heading=" Winding Up of a Company in India" text1="Liquidate your company without any hassles and without breaching any compliances. Talk to an expert from Tax Service right now!"  />
          <Home5></Home5>
          <Home6></Home6>
          <Footer/>
    </div>
  )
}

export default Close_the_Pvt_Ltd_Company
